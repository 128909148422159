import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';

class polarchart extends Component {
    render() {
        var polarchartData = {
            labels: [1, 2, 3, 4, 5, 6, 7, 8],
            series: [
                [1, 2, 3, 1, -2, 0, 1, 0],
                [-2, -1, -2, -1, -2.5, -1, -2, -1],
                [0, 0, 0, 1, 2, 2.5, 2, 1],
                [2.5, 2, 1, 0.5, 1, 0.5, -1, -2.5]
            ]
        };
        var polarchartOptions = {
            high: 3,
            low: -3,
            showArea: true,
            showLine: false,
            showPoint: false,
            fullWidth: true,
            axisX: {
                showLabel: false,
                showGrid: false
            },
        };

        return (
            <React.Fragment>
                <ChartistGraph data={polarchartData} options={polarchartOptions}  type={'Line'}  />
            </React.Fragment>
        );
    }
}

export default polarchart;