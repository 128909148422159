import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class FormLayouts extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Inline form</h4>
                                <Form>
                                    <Row>
                                        <Col sm="3">
                                            <Input type="text" defaultValue="Jane Doe" id="example-text-input" />
                                        </Col>
                                        <Col sm="3">
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>@</InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="username" />
                                            </InputGroup>
                                        </Col>
                                        <Col sm="auto">
                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                <Input type="checkbox" className="custom-control-input"
                                                    id="customCheckbox1" defaultChecked />
                                                <Label className="custom-control-label" for="customCheckbox1">Remember me</Label>
                                            </div>
                                        </Col>
                                        <Col sm="auto">
                                            <Button type="button" color="primary" className="btn-fixed-w mb-3 mr-2">Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Input Rounded</h4>
                                <Form>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <Input type="text" placeholder=".form-control-lg" className="input-rounded form-control-lg" id="example-text-input" />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <Input type="text" placeholder="Default input" className="input-rounded form-control" id="example-text-input" />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <Input type="text" placeholder=".form-control-sm" className="input-rounded form-control-sm" id="example-text-input" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Disabled forms</h4>
                                <Form>
                                    <fieldset disabled>
                                        <FormGroup>
                                            <Label htmlFor="disabledTextInput">Disabled input</Label>
                                            <Input type="text" placeholder="Disabled input" id="disabledTextInput" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="disabledSelect">Disabled select</Label>
                                            <select className="form-control" id="disabledSelect">
                                                <option>Select</option>
                                                <option>Large select</option>
                                                <option>Small select</option>
                                            </select>
                                        </FormGroup>
                                        <Button type="button" color="primary" className="btn-fixed-w mb-3 mr-2">Submit</Button>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(FormLayouts));