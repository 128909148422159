import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import image1 from "../../assets/images/image-attach.jpg";

class ReadMail extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <h3 className="mb-0">
                                    Inbox
                                </h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="3" sm="12">
                        <Card>
                            <CardBody>
                                <Link to="/compose" className="btn btn-primary btn-block">Compose</Link>
                                <ul className="list-unstyled mail_tabs">
                                    <li className="active">
                                        <Link to="/#">
                                            <i className="fa fa-inbox"></i> Inbox <span
                                                className="badge badge-primary pull-right">6</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-send-o"></i> Sent
                                    </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-edit"></i> Drafts <span
                                                className="badge badge-accent pull-right">2</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-star-o"></i> Important
                                    </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-trash-o"></i> Trash
                                    </Link>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="9" sm="12" className="mt-mob-4">
                        <Card>
                            <CardBody>
                                <div className="mail_content">
                                    <Row>
                                        <div className="col-lg-12">
                                            <h5 className="pull-left mb-mob-4">
                                                Please Confirm your Email
                                                <span className="d-block recipient">From: help@example.com</span>
                                                <span className="mailbox-time recipient">15 Feb. 2016 11:03 PM</span>
                                            </h5>
                                            <ButtonGroup className="mr-2 pull-right">
                                                <Button type="button" color="primary"><i className="ion-ios-trash"></i></Button>
                                                <Button type="button" color="primary"><i className="ion-ios-undo"></i></Button>
                                                <Button type="button" color="primary"><i className="ion-forward"></i></Button>
                                                <Button type="button" color="primary"><i className="ion-printer"></i></Button>
                                            </ButtonGroup>
                                        </div>
                                        <div className="mail_message col-lg-12">
                                            <div className="mt-5">
                                                <p><strong>Hello,</strong></p>
                                                <p>Natus consequuntur perspiciatis esse beatae illo quos eaque.</p>
                                                <p>Earum, quisquam, fugit? Numquam dolor magni nisi? Suscipit odit, ipsam
                                                iusto enim culpa, temporibus vero possimus error voluptates sequi. Iusto
                                                ipsam, nihil? Eveniet modi maxime animi excepturi a dignissimos
                                                doloribus,
                                                inventore sed ratione, ducimus atque earum maiores tenetur officia
                                                commodi dicta tempora consequatur non nesciunt ipsam, consequuntur quia
                                                fuga aspernatur impedit et? Natus, earum.</p>
                                                <blockquote className="blockquote">
                                                    Earum, quisquam, fugit? Numquam dolor magni nisi? Suscipit odit, ipsam
                                                    iusto enim culpa, temporibus vero possimus error voluptates sequi.
                                            </blockquote>
                                                <p>Earum, quisquam, fugit? Numquam dolor magni nisi? Suscipit odit, ipsam
                                                iusto enim culpa, temporibus vero possimus error voluptates sequi. Iusto
                                                ipsam, nihil? Eveniet modi maxime animi excepturi a dignissimos
                                                doloribus,
                                                inventore sed ratione, ducimus atque earum maiores tenetur officia
                                                commodi dicta tempora consequatur non nesciunt ipsam, consequuntur quia
                                                fuga aspernatur impedit et? Natus, earum.</p><br /> Regards
                                            <br /> Json Roy
                                        </div>
                                            <div className="mail_footer mt-4">
                                                <ul className="mailbox-attachments clearfix">
                                                    <li>
                                                        <span className="mailbox-attachment-icon">
                                                            <i className="fa fa-file-pdf-o"></i>
                                                        </span>

                                                        <div className="mailbox-attachment-info">
                                                            <Link to="/#" className="mailbox-attachment-name">
                                                                <i className="fa fa-paperclip"></i> Sep2014-report.pdf
                                                        </Link>
                                                            <span className="mailbox-attachment-size">
                                                                1,245 KB
                                                            <Link to="/#" className="btn btn-primary pull-right"><i className="fa fa-cloud-download"></i></Link>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="mailbox-attachment-icon">
                                                            <i className="fa fa-file-word-o"></i>
                                                        </span>

                                                        <div className="mailbox-attachment-info">
                                                            <Link to="/#" className="mailbox-attachment-name">
                                                                <i className="fa fa-paperclip"></i> App
                                                            Description.docx
                                                        </Link>
                                                            <span className="mailbox-attachment-size">
                                                                1,245 KB
                                                            <Link to="/#" className="btn btn-primary pull-right"><i className="fa fa-cloud-download"></i></Link>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="mailbox-attachment-icon has-img">
                                                            <img src={image1} alt="Attachment" />
                                                        </span>

                                                        <div className="mailbox-attachment-info">
                                                            <Link to="/#" className="mailbox-attachment-name">
                                                                <i className="fa fa-camera"></i> photo1.png
                                                        </Link>
                                                            <span className="mailbox-attachment-size">
                                                                2.67 MB
                                                            <Link to="/#" className="btn btn-primary pull-right"><i className="fa fa-cloud-download"></i></Link>
                                                            </span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <hr />
                                            <div className="reply_btn pull-left">
                                                <Button type="button" color="primary" className="btn-fixed-w mb-3 mr-2">
                                                    <i className="ion-ios-undo"></i>
                                                    Reply
                                                </Button>
                                                <Button type="button" color="primary" className="btn-fixed-w mb-3 mr-2">
                                                    <i className="ion-forward"></i>
                                                    Forward
                                                </Button>
                                            </div>
                                            <div className="reply_btn pull-right mb-mob-3">
                                                <Button type="button" color="danger" className="btn-fixed-w mb-3 mr-2">
                                                    <i className="ion-ios-trash"></i>
                                                    Delete
                                                </Button>
                                                <Button type="button" color="secondary" className="btn-fixed-w mb-3 mr-2">
                                                    <i className="ion-ios-trash"></i>
                                                    Print
                                                </Button>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(ReadMail));