import React, { Component } from 'react';
import { Radar } from 'react-chartjs-2';

class RadarChart extends Component {


    render() {
        const data = {
            labels: ["Data 1", "Data 2", "Data 3", "Data 4"],
            datasets: [{
                label: 'Main Data',
                backgroundColor: "rgba(151, 104, 209,0.5)",
                borderColor: "rgba(151, 104, 209,0.7)",
                data: [12, 19, 3, 17],
                borderWidth: 2
            }, {
                label: 'Basic Data',
                backgroundColor: "rgba(123, 82, 171,0.5)",
                borderColor: "rgba(123, 82, 171,0.7)",
                data: [30, 29, 5, 5, 20, 3, 10],
                borderWidth: 2
            }]
        };

        const option = {
            maintainAspectRatio: false,

            legend: {
                display: true,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                }
            },
        }

        return (
            <React.Fragment>
                <Radar width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default RadarChart;   