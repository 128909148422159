import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';

class stackedbarchart extends Component {
    render() {
        var barChartData = {
            labels: ['Q1', 'Q2', 'Q3', 'Q4'],
            series: [
                [800000, 1200000, 1400000, 1300000],
                [200000, 400000, 500000, 300000],
                [100000, 200000, 400000, 600000]
            ]
        };
        var barChartOptions = {
            stackBars: true,
            axisY: {
                labelInterpolationFnc: function (value) {
                    return (value / 1000) + 'k';
                }
            }
        }
        return (
            <React.Fragment>
                <ChartistGraph data={barChartData} options={barChartOptions} type={'Bar'} />
            </React.Fragment>
        );
    }
}

export default stackedbarchart;