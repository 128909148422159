import React, { Component } from 'react';
import { Row, Col, Card, CardBody} from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LineApexChart from '../InitCharts/apex/chartapex';
import BarApexChart from '../InitCharts/apex/barchart';
import AreaChart from '../InitCharts/apex/areachart';
import DonutChart from '../InitCharts/apex/dountchart';

class ApexChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="mt-0 header-title">Line Chart</h4>
                                <LineApexChart />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="mt-0 header-title">Bar Chart</h4>
                                <BarApexChart />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="mt-0 header-title">Area Chart</h4>
                                <AreaChart />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="mt-1 header-title">Donut Chart</h4>
                                <div className="m-3 p-3">
                                    <DonutChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(ApexChart));