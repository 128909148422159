import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Loaders extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Ring Loader</h4>
                                <div className="ring_loader primary_ring_loader">
                                    <div></div><div></div><div></div><div></div>
                                </div>
                                <div className="ring_loader secondary_ring_loader">
                                    <div></div><div></div><div></div><div></div>
                                </div>
                                <div className="ring_loader warning_ring_loader">
                                    <div></div><div></div><div></div><div></div>
                                </div>
                                <div className="ring_loader danger_ring_loader">
                                    <div></div><div></div><div></div><div></div>
                                </div>
                                <div className="ring_loader success_ring_loader">
                                    <div></div><div></div><div></div><div></div>
                                </div>
                                <div className="ring_loader dark_ring_loader">
                                    <div></div><div></div><div></div><div></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Default Loader</h4>
                                <div className="default_loader">
                                    <div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div><div className="bg-primary"></div>
                                </div>
                                <div className="default_loader">
                                    <div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div><div className="bg-secondary"></div>
                                </div>
                                <div className="default_loader">
                                    <div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div><div className="bg-danger"></div>
                                </div>
                                <div className="default_loader">
                                    <div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div><div className="bg-success"></div>
                                </div>
                                <div className="default_loader">
                                    <div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div><div className="bg-warning"></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Circle Loader</h4>
                                <div className="loader_dual primary_dual_loader mr-2"></div>
                                <div className="loader_dual secondary_dual_loader mr-2"></div>
                                <div className="loader_dual warning_dual_loader mr-2"></div>
                                <div className="loader_dual danger_dual_loader mr-2"></div>
                                <div className="loader_dual success_dual_loader mr-2"></div>
                                <div className="loader_dual dark_dual_loader mr-2"></div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Roller Loader</h4>
                                <div className="roller_loader primary_roller_loader">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                                <div className="roller_loader secondary_roller_loader">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                                <div className="roller_loader danger_roller_loader">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                                <div className="roller_loader warning_roller_loader">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                                <div className="roller_loader dark_roller_loader">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Ripple Loader</h4>
                                <div className="loader_rippler primary_ripple_loader"><div></div><div></div></div>
                                <div className="loader_rippler secondary_ripple_loader"><div></div><div></div></div>
                                <div className="loader_rippler danger_ripple_loader"><div></div><div></div></div>
                                <div className="loader_rippler warning_ripple_loader"><div></div><div></div></div>
                                <div className="loader_rippler success_ripple_loader"><div></div><div></div></div>
                                <div className="loader_rippler dark_ripple_loader"><div></div><div></div></div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Spinner Loader</h4>
                                <div className="spinner_loader primary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                <div className="spinner_loader danger_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                <div className="spinner_loader warning_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                <div className="spinner_loader success_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                <div className="spinner_loader dark_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Loaders));