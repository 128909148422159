import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Timeline extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Timeline</h4>
                                <div className="recent-activity">
                                    <div className="timeline-task">
                                        <div className="icon bg_secondary">
                                            <i className="feather ft-message-square"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>You got 1 New Message</h4>
                                            <span className="time"><i className="ti-time"></i>09:35</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse distinctio itaque at.
                                    </p>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_success">
                                            <i className="feather ft-check"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>Your Verification Successful</h4>
                                            <span className="time"><i className="ti-time"></i>7 Minutes Ago</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur.
                                    </p>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_danger">
                                            <i className="feather ft-alert-triangle"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>Something Went Wrong</h4>
                                            <span className="time"><i className="ti-time"></i>09:20 Am</span>
                                        </div>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_warning">
                                            <i className="feather ft-user"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>Member waiting for your Response</h4>
                                            <span className="time"><i className="ti-time"></i>09:35</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse distinctio itaque at.
                                    </p>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_info">
                                            <i className="feather ft-trash-2"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>You Deleted Jhon Doe</h4>
                                            <span className="time"><i className="ti-time"></i>01 minutes ago</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse distinctio itaque at.
                                    </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Timeline));