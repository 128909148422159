import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Buttons extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">General Buttons</h4>
                                <Button type="button" color="primary" className="btn-fixed-w mb-3 mr-2">Primary</Button>
                                <Button type="button" color="secondary" className="btn-fixed-w mb-3 mr-2">Secondary</Button>
                                <Button type="button" color="success" className="btn-fixed-w mb-3 mr-2">Success</Button>
                                <Button type="button" color="info" className="btn-fixed-w mb-3 mr-2">Info</Button>
                                <Button type="button" color="warning" className="btn-fixed-w mb-3 mr-2">Warning</Button>
                                <Button type="button" color="danger" className="btn-fixed-w mb-3 mr-2">Danger</Button>
                                <Button type="button" color="dark" className="btn-fixed-w mb-3 mr-2">Dark</Button>
                                <Button type="button" color="light" className="btn-fixed-w mb-3 mr-2">Light</Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Flat Buttons</h4>
                                <Button type="button" color="primary" className="btn-flat btn-fixed-w mb-3 mr-2">Primary</Button>
                                <Button type="button" color="secondary" className="btn-flat btn-fixed-w mb-3 mr-2">Secondary</Button>
                                <Button type="button" color="success" className="btn-flat btn-fixed-w mb-3 mr-2">Success</Button>
                                <Button type="button" color="info" className="btn-flat btn-fixed-w mb-3 mr-2">Info</Button>
                                <Button type="button" color="warning" className="btn-flat btn-fixed-w mb-3 mr-2">Warning</Button>
                                <Button type="button" color="danger" className="btn-flat btn-fixed-w mb-3 mr-2">Danger</Button>
                                <Button type="button" color="dark" className="btn-flat btn-fixed-w mb-3 mr-2">Dark</Button>
                                <Button type="button" color="light" className="btn-flat btn-fixed-w mb-3 mr-2">Light</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Rounded Buttons</h4>
                                <Button type="button" color="primary" className="btn-rounded btn-fixed-w mb-3 mr-2">Primary</Button>
                                <Button type="button" color="secondary" className="btn-rounded btn-fixed-w mb-3 mr-2">Secondary</Button>
                                <Button type="button" color="success" className="btn-rounded btn-fixed-w mb-3 mr-2">Success</Button>
                                <Button type="button" color="info" className="btn-rounded btn-fixed-w mb-3 mr-2">Info</Button>
                                <Button type="button" color="warning" className="btn-rounded btn-fixed-w mb-3 mr-2">Warning</Button>
                                <Button type="button" color="danger" className="btn-rounded btn-fixed-w mb-3 mr-2">Danger</Button>
                                <Button type="button" color="dark" className="btn-rounded btn-fixed-w mb-3 mr-2">Dark</Button>
                                <Button type="button" color="light" className="btn-rounded btn-fixed-w mb-3 mr-2">Light</Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Button tags</h4>
                                <Link className="btn btn-primary btn-fixed-w mb-3 mr-2" to="#" role="button">Link</Link>
                                <input className="btn btn-secondary btn-fixed-w mb-3 mr-2" type="reset" value="Reset" />
                                <input className="btn btn-success btn-fixed-w mb-3 mr-2" type="submit" value="Input" />
                                <input className="btn btn-dark btn-fixed-w mb-3 mr-2" type="submit" value="Submit" />
                                <input className="btn btn-info btn-fixed-w mb-3 mr-2" type="submit" value="Reset" />
                                <Link className="btn btn-warning btn-fixed-w mb-3 mr-2" to="#" role="button">Link</Link>
                                <input className="btn btn-danger btn-fixed-w mb-3 mr-2" type="reset" value="Reset" />
                                <input className="btn btn-light btn-fixed-w mb-3 mr-2" type="submit" value="Input" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Outline buttons</h4>
                                <Button type="button" outline color="primary" className="btn-fixed-w mb-3 mr-2">Primary</Button>
                                <Button type="button" outline color="secondary" className="btn-fixed-w mb-3 mr-2">Secondary</Button>
                                <Button type="button" outline color="success" className="btn-fixed-w mb-3 mr-2">Success</Button>
                                <Button type="button" outline color="info" className="btn-fixed-w mb-3 mr-2">Info</Button>
                                <Button type="button" outline color="warning" className="btn-fixed-w mb-3 mr-2">Warning</Button>
                                <Button type="button" outline color="danger" className="btn-fixed-w mb-3 mr-2">Danger</Button>
                                <Button type="button" outline color="dark" className="btn-fixed-w mb-3 mr-2">Dark</Button>
                                <Button type="button" outline color="light" className="btn-fixed-w mb-3 mr-2">Light</Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Outline buttons Round</h4>
                                <Button type="button" outline color="primary" className="btn-rounded btn-fixed-w mb-3 mr-2">Primary</Button>
                                <Button type="button" outline color="secondary" className="btn-rounded btn-fixed-w mb-3 mr-2">Secondary</Button>
                                <Button type="button" outline color="success" className="btn-rounded btn-fixed-w mb-3 mr-2">Success</Button>
                                <Button type="button" outline color="info" className="btn-rounded btn-fixed-w mb-3 mr-2">Info</Button>
                                <Button type="button" outline color="warning" className="btn-rounded btn-fixed-w mb-3 mr-2">Warning</Button>
                                <Button type="button" outline color="danger" className="btn-rounded btn-fixed-w mb-3 mr-2">Danger</Button>
                                <Button type="button" outline color="dark" className="btn-rounded btn-fixed-w mb-3 mr-2">Dark</Button>
                                <Button type="button" outline color="light" className="btn-rounded btn-fixed-w mb-3 mr-2">Light</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Sizes buttons</h4>
                                <Button type="button" color="info" size="xl" className="mb-3 mr-2">Large button</Button>
                                <Button type="button" color="primary" size="lg" className="mb-3 mr-2">Large button</Button>
                                <Button type="button" color="success" size="md" className="mb-3 mr-2">Small button</Button>
                                <Button type="button" color="warning" size="sm" className="mb-3 mr-2">Small button</Button>
                                <Button type="button" color="danger" size="xs" className="mb-3 mr-2">Small button</Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Block buttons</h4>
                                <Button type="button" color="primary" size="lg" className="btn-block">Block level button</Button>
                                <Button type="button" color="secondary" size="lg" className="btn-block">Block level button</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Inverse buttons</h4>
                                <Button type="button" className="btn-inverse-primary btn-fixed-w mb-3 mr-2">Primary</Button>
                                <Button type="button" className="btn-inverse-secondary btn-fixed-w mb-3 mr-2">Secondary</Button>
                                <Button type="button" className="btn-inverse-success btn-fixed-w mb-3 mr-2">Success</Button>
                                <Button type="button" className="btn-inverse-info btn-fixed-w mb-3 mr-2">Info</Button>
                                <Button type="button" className="btn-inverse-warning btn-fixed-w mb-3 mr-2">Warning</Button>
                                <Button type="button" className="btn-inverse-danger btn-fixed-w mb-3 mr-2">Danger</Button>
                                <Button type="button" className="btn-inverse-dark btn-fixed-w mb-3 mr-2">Dark</Button>
                                <Button type="button" className="btn-inverse-light btn-fixed-w mb-3 mr-2">Light</Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Inverse Rounded buttons</h4>
                                <Button type="button" className="btn-inverse-primary btn-rounded btn-fixed-w mb-3 mr-2">Primary</Button>
                                <Button type="button" className="btn-inverse-secondary btn-rounded btn-fixed-w mb-3 mr-2">Secondary</Button>
                                <Button type="button" className="btn-inverse-success btn-rounded btn-fixed-w mb-3 mr-2">Success</Button>
                                <Button type="button" className="btn-inverse-info btn-rounded btn-fixed-w mb-3 mr-2">Info</Button>
                                <Button type="button" className="btn-inverse-warning btn-rounded btn-fixed-w mb-3 mr-2">Warning</Button>
                                <Button type="button" className="btn-inverse-danger btn-rounded btn-fixed-w mb-3 mr-2">Danger</Button>
                                <Button type="button" className="btn-inverse-dark btn-rounded btn-fixed-w mb-3 mr-2">Dark</Button>
                                <Button type="button" className="btn-inverse-light btn-rounded btn-fixed-w mb-3 mr-2">Light</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Social Media Buttons</h4>
                                <Button type="button" color="initial" className="btn-social btn-social-fb mb-3 mr-2"><i className="ti-facebook"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-tw mb-3 mr-2"><i className="ti-twitter"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-in mb-3 mr-2"><i className="ti-instagram"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-li mb-3 mr-2"><i className="ti-linkedin"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-pi mb-3 mr-2"><i className="ti-pinterest"></i></Button>
                                <br/>
                                <Button type="button" color="initial" className="btn-social btn-social-outline-fb mb-3 mr-2"><i className="ti-facebook"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-outline-tw mb-3 mr-2"><i className="ti-twitter"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-outline-in mb-3 mr-2"><i className="ti-instagram"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-outline-li mb-3 mr-2"><i className="ti-linkedin"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-social-outline-pi mb-3 mr-2"><i className="ti-pinterest"></i></Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Social Media Buttons</h4>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-fb mb-3 mr-2"><i className="ti-facebook"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-tw mb-3 mr-2"><i className="ti-twitter"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-in mb-3 mr-2"><i className="ti-instagram"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-li mb-3 mr-2"><i className="ti-linkedin"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-pi mb-3 mr-2"><i className="ti-pinterest"></i></Button>
                                <br/>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-outline-fb mb-3 mr-2"><i className="ti-facebook"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-outline-tw mb-3 mr-2"><i className="ti-twitter"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-outline-in mb-3 mr-2"><i className="ti-instagram"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-outline-li mb-3 mr-2"><i className="ti-linkedin"></i></Button>
                                <Button type="button" color="initial" className="btn-social btn-rounded btn-social-outline-pi mb-3 mr-2"><i className="ti-pinterest"></i></Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Basic Button Group</h4>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="primary">Left</Button>
                                    <Button type="button" color="primary">Middle</Button>
                                    <Button type="button" color="primary">Right</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="secondary">Left</Button>
                                    <Button type="button" color="secondary">Middle</Button>
                                    <Button type="button" color="secondary">Right</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="success">Left</Button>
                                    <Button type="button" color="success">Middle</Button>
                                    <Button type="button" color="success">Right</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="danger">Left</Button>
                                    <Button type="button" color="danger">Middle</Button>
                                    <Button type="button" color="danger">Right</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="warning">Left</Button>
                                    <Button type="button" color="warning">Middle</Button>
                                    <Button type="button" color="warning">Right</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="info">Left</Button>
                                    <Button type="button" color="info">Middle</Button>
                                    <Button type="button" color="info">Right</Button>
                                </ButtonGroup>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Basic toolbar</h4>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="primary"><i className="ti-heart"></i></Button>
                                    <Button type="button" color="primary"><i className="ti-settings"></i></Button>
                                    <Button type="button" color="primary"><i className="ti-reload"></i></Button>
                                    <Button type="button" color="primary"><i className="ti-paint-bucket"></i></Button>
                                    <Button type="button" color="primary"><i className="ti-shine"></i></Button>
                                    <Button type="button" color="primary"><i className="ti-timer"></i></Button>
                                    <Button type="button" color="primary"><i className="ti-world"></i></Button>
                                    <Button type="button" color="primary"><i className="ti-power-off"></i></Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="secondary"><i className="ti-heart"></i></Button>
                                    <Button type="button" color="secondary"><i className="ti-settings"></i></Button>
                                    <Button type="button" color="secondary"><i className="ti-reload"></i></Button>
                                    <Button type="button" color="secondary"><i className="ti-power-off"></i></Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="info">12</Button>
                                    <Button type="button" color="info">10</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="warning"><i className="ti-heart"></i></Button>
                                    <Button type="button" color="warning"><i className="ti-settings"></i></Button>
                                    <Button type="button" color="warning"><i className="ti-reload"></i></Button>
                                    <Button type="button" color="warning"><i className="ti-paint-bucket"></i></Button>
                                    <Button type="button" color="warning"><i className="ti-power-off"></i></Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" outline color="primary"><i className="ti-heart"></i></Button>
                                    <Button type="button" outline color="primary"><i className="ti-settings"></i></Button>
                                    <Button type="button" outline color="primary"><i className="ti-reload"></i></Button>
                                    <Button type="button" outline color="primary"><i className="ti-paint-bucket"></i></Button>
                                    <Button type="button" outline color="primary"><i className="ti-power-off"></i></Button>
                                </ButtonGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Button Group Sizing</h4>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="primary" className="btn-lg">Left</Button>
                                    <Button type="button" color="primary" className="btn-lg">Middle</Button>
                                    <Button type="button" color="primary" className="btn-lg">Right</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="secondary" className="btn-md">Left</Button>
                                    <Button type="button" color="secondary" className="btn-md">Middle</Button>
                                    <Button type="button" color="secondary" className="btn-md">Right</Button>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="success" className="btn-sm">Left</Button>
                                    <Button type="button" color="success" className="btn-sm">Middle</Button>
                                    <Button type="button" color="success" className="btn-sm">Right</Button>
                                </ButtonGroup>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Button Nesting</h4>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="primary">1</Button>
                                    <Button type="button" color="primary">2</Button>
                                    <ButtonDropdown isOpen={this.state.drp_link} toggle={() => this.setState({ drp_link: !this.state.drp_link })}>
                                        <DropdownToggle caret color="primary">
                                            Dropdown{' '}</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="secondary">1</Button>
                                    <Button type="button" color="secondary">2</Button>
                                    <ButtonDropdown isOpen={this.state.drp_link} toggle={() => this.setState({ drp_link: !this.state.drp_link })}>
                                        <DropdownToggle caret color="secondary">
                                            Dropdown{' '}</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="success">1</Button>
                                    <Button type="button" color="success">2</Button>
                                    <ButtonDropdown isOpen={this.state.drp_link} toggle={() => this.setState({ drp_link: !this.state.drp_link })}>
                                        <DropdownToggle caret color="success">
                                            Dropdown{' '}</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                                <ButtonGroup className="mb-3 mr-2">
                                    <Button type="button" color="danger">1</Button>
                                    <Button type="button" color="danger">2</Button>
                                    <ButtonDropdown isOpen={this.state.drp_link} toggle={() => this.setState({ drp_link: !this.state.drp_link })}>
                                        <DropdownToggle caret color="danger">
                                            Dropdown{' '}</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                            <DropdownItem>Dropdown link</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Buttons with Icons</h4>
                                <Button type="button" color="primary" className="mb-3 mr-2"><i className="ti-heart"></i> Primary</Button>
                                <Button type="button" color="secondary" className="mb-3 mr-2"><i className="ti-settings"></i>Secondary</Button>
                                <Button type="button" color="success" className="mb-3 mr-2"><i className="ti-reload"></i>Success</Button>
                                <Button type="button" color="info" className="mb-3 mr-2"><i className="ti-paint-bucket"></i>Info</Button>
                                <Button type="button" color="warning" className="mb-3 mr-2"><i className="ti-shine"></i>Warning</Button>
                                <Button type="button" color="danger" className="mb-3 mr-2"><i className="ti-world"></i>Danger</Button>
                                <Button type="button" color="dark" className="mb-3 mr-2"><i className="ti-timer"></i>Dark</Button>
                                <Button type="button" color="light" className="mb-3 mr-2"><i className="ti-power-off"></i>Light</Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Buttons with Icons Outline</h4>
                                <Button type="button" outline color="primary" className="mb-3 mr-2"><i className="ti-heart"></i> Primary</Button>
                                <Button type="button" outline color="secondary" className="mb-3 mr-2"><i className="ti-settings"></i>Secondary</Button>
                                <Button type="button" outline color="success" className="mb-3 mr-2"><i className="ti-reload"></i>Success</Button>
                                <Button type="button" outline color="info" className="mb-3 mr-2"><i className="ti-paint-bucket"></i>Info</Button>
                                <Button type="button" outline color="warning" className="mb-3 mr-2"><i className="ti-shine"></i>Warning</Button>
                                <Button type="button" outline color="danger" className="mb-3 mr-2"><i className="ti-world"></i>Danger</Button>
                                <Button type="button" outline color="dark" className="mb-3 mr-2"><i className="ti-timer"></i>Dark</Button>
                                <Button type="button" outline color="light" className="mb-3 mr-2"><i className="ti-power-off"></i>Light</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Buttons));