import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Table, Button, Progress } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

class BasicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>
                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Basic Table</h4>
                                <Table responsive className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Striped rows</h4>
                                <Table responsive striped className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Bordered table</h4>
                                <Table responsive bordered className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>

                        </Card>
                    </Col>
                    <Col lg="6" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Hoverable rows</h4>
                                <Table responsive hover className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">THEAD LIGHT</h4>
                                <Table responsive className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">THEAD DARK</h4>
                                <Table responsive className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Contextual classes</h4>
                                <Table responsive className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <i className="ti-trash"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <i className="ti-trash"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <i className="ti-trash"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Borderless table</h4>
                                <Table responsive borderless className="mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">date</th>
                                            <th scope="col">price</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>jone</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$150</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>$120</td>
                                            <td>
                                                <Button color="danger"><i className="ti-trash"></i></Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">PROGRESS TABLE</h4>
                                <Table responsive className="mb-0 table-hover progress-table text-center">
                                    <thead className="text-uppercase">
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">task</th>
                                            <th scope="col">Deadline</th>
                                            <th scope="col">Progress</th>
                                            <th scope="col">status</th>
                                            <th scope="col">action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>
                                                <Progress color="info" value={25}></Progress>
                                            </td>
                                            <td><span className="badge badge-info">in progress</span></td>
                                            <td>
                                                <ul className="d-flex justify-content-center">
                                                    <li className="mr-3"><Link to="/#" className="text-primary"><i className="fa fa-edit"></i></Link></li>
                                                    <li><Link to="/#" className="text-danger"><i className="ti-trash"></i></Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>
                                                <Progress color="warning" value={80}></Progress>
                                            </td>
                                            <td><span className="badge badge-warning">pending</span></td>
                                            <td>
                                                <ul className="d-flex justify-content-center">
                                                    <li className="mr-3"><Link to="/#" className="text-primary"><i className="fa fa-edit"></i></Link></li>
                                                    <li><Link to="/#" className="text-danger"><i className="ti-trash"></i></Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>
                                                <Progress color="success" value={100}></Progress>
                                            </td>
                                            <td><span className="badge badge-success">complate</span></td>
                                            <td>
                                                <ul className="d-flex justify-content-center">
                                                    <li className="mr-3"><Link to="/#" className="text-primary"><i className="fa fa-edit"></i></Link></li>
                                                    <li><Link to="/#" className="text-danger"><i className="ti-trash"></i></Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">4</th>
                                            <td>Mark</td>
                                            <td>09 / 07 / 2018</td>
                                            <td>
                                                <Progress color="danger" value={85}></Progress>
                                            </td>
                                            <td><span className="badge badge-danger">stopped</span></td>
                                            <td>
                                                <ul className="d-flex justify-content-center">
                                                    <li className="mr-3"><Link to="/#" className="text-primary"><i className="fa fa-edit"></i></Link></li>
                                                    <li><Link to="/#" className="text-danger"><i className="ti-trash"></i></Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(BasicTable));