import React, { Component } from 'react';
import C3Chart from 'react-c3js';

class areachart extends Component {
    render() {
        var areachartData = {
            columns: [
                ['data1', 300, 350, 300, 100, 50, 250],
                ['data2', 130, 100, 140, 200, 150, 50]
            ],
            types: {
                data1: 'area-spline',
                data2: 'area-spline'
            },
            colors: {
                data1: '#9768D1',
                data2: '#7B52AB',

            }
        }
        var axisOptions = {
            y: {
                show: true
            },
            x: {
                show: true
            }
        }

        return (
            <React.Fragment>
                <C3Chart data={areachartData} axis={axisOptions} />
            </React.Fragment>
        );
    }
}

export default areachart;