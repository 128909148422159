import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactAce from 'react-ace-editor';

class CodeEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Javascript Editor</h4>
                                <ReactAce
                                    mode="javascript"
                                    theme="monokai"
                                    setReadOnly={false}
                                    // onChange={this.onChange}
                                    style={{ height: '300px' }}
                                    ref={instance => { this.ace = instance; }} // Let's put things into scope
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(CodeEditor));