import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Pricing</h4>
                                <div id="mt_pricing">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="main_pricing_conatiner wow fadeInLeftBig" data-wow-delay="0.3">
                                                    <div className="price">
                                                        <h2>
                                                            <span className="price_icon">
                                                                <i className="icon-wallet"></i>
                                                            </span>
                                                        Standard
                                                    </h2>
                                                        <span className="price_tag">
                                                            <span className="currency">$</span>0
                                                    </span>
                                                        <span className="per_month">/Month</span>
                                                    </div>
                                                    <div className="price_listing">
                                                        <ul>
                                                            <li>Latin words, consectetur.</li>
                                                            <li>All the Lorem Ipsum.</li>
                                                            <li>It has survived not only</li>
                                                            <li>Labore et dolore magna ali.</li>
                                                            <li>Nor again is there anyone.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="choose_plan_btn">
                                                        <Link to="#" className="btn btn-primary">CHOOSE PLAN</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="main_pricing_conatiner highlight_pricing wow fadeInUpBig" data-wow-delay="0.6s">
                                                    <div className="price">
                                                        <h2>
                                                            <span className="price_icon">
                                                                <i className="icon-briefcase"></i>
                                                            </span>Bussiness
                                                    </h2>
                                                        <span className="price_tag">
                                                            <span className="currency">$</span>90
                                                    </span>
                                                        <span className="per_month">/Month</span>
                                                    </div>
                                                    <div className="price_listing">
                                                        <ul>
                                                            <li>Latin words, consectetur.</li>
                                                            <li>All the Lorem Ipsum.</li>
                                                            <li>It has survived not only</li>
                                                            <li>Labore et dolore magna ali.</li>
                                                            <li>Nor again is there anyone.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="choose_plan_btn">
                                                        <Link to="#" className="btn btn-light">CHOOSE PLAN</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="main_pricing_conatiner wow fadeInRightBig" data-wow-delay="0.9s">
                                                    <div className="price">
                                                        <h2>
                                                            <span className="price_icon">
                                                                <i className="icon-genius"></i>
                                                            </span>Enterprise
                                                    </h2>
                                                        <span className="price_tag">
                                                            <span className="currency">$</span>120
                                                    </span>
                                                        <span className="per_month">/Month</span>
                                                    </div>
                                                    <div className="price_listing">
                                                        <ul>
                                                            <li>Latin words, consectetur.</li>
                                                            <li>All the Lorem Ipsum.</li>
                                                            <li>It has survived not only</li>
                                                            <li>Labore et dolore magna ali.</li>
                                                            <li>Nor again is there anyone.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="choose_plan_btn">
                                                        <Link to="#" className="btn btn-primary">CHOOSE PLAN</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Pricing));