import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            activeTab1: '4',
            activeTab2: '6',
            activeTab3: '9',
            activeTab_border: '9',
            activeTab_border1: '13',
            col1: true,
            col2: false,
            col3: false,
            col5: true,
        };

        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);

        this.t_col1 = this.t_col1.bind(this);
        this.t_col2 = this.t_col2.bind(this);
        this.t_col3 = this.t_col3.bind(this);
        this.t_col5 = this.t_col5.bind(this);

        this.t_border = this.t_border.bind(this);
        this.t_border1 = this.t_border1.bind(this);

    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    t_col1() {
        this.setState({ col1: !this.state.col1 });
    }
    t_col2() {
        this.setState({ col2: !this.state.col2 });
    }
    t_col3() {
        this.setState({ col3: !this.state.col3 });
    }
    t_col5() {
        this.setState({ col5: !this.state.col5 });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });
        }
    }

    toggle2(tab) {
        if (this.state.activeTab2 !== tab) {
            this.setState({
                activeTab2: tab
            });
        }
    }
    toggle3(tab) {
        if (this.state.activeTab3 !== tab) {
            this.setState({
                activeTab3: tab
            });
        }
    }


    t_border(tab) {
        if (this.state.activeTab_border !== tab) {
            this.setState({
                activeTab_border: tab
            });
        }
    }
    t_border1(tab) {
        if (this.state.activeTab_border1 !== tab) {
            this.setState({
                activeTab_border1: tab
            });
        }
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Basic Tab</h4>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggle('1'); }} >
                                            <i className="ti-home"></i> Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }} >
                                            <i className="ti-user"></i> Profile
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '3' })}
                                            onClick={() => { this.toggle('3'); }}>
                                            <i className="ti-mobile"></i> Contact
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab} >
                                    <TabPane tabId="1" className="p-3">
                                        <p className="mb-0">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
                                                </p>
                                    </TabPane>
                                    <TabPane tabId="2" className="p-3" >
                                        <p className="mb-0">
                                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                                </p>
                                    </TabPane>
                                    <TabPane tabId="3" className="p-3" >
                                        <p className="mb-0">
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.
                                                </p>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Tab With Dropdown</h4>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab1 === '4' })}
                                            onClick={() => { this.toggle1('4'); }} >
                                            <i className="ti-home"></i> Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <UncontrolledDropdown >
                                            <DropdownToggle color='' caret className="nav-link">
                                                Dropdown {' '}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Dropdown</DropdownItem>
                                                <DropdownItem>Another action</DropdownItem>
                                                <DropdownItem>Something else here</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab1 === '5' })}
                                            onClick={() => { this.toggle1('5'); }}>
                                            <i className="ti-mobile"></i> Contact
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab1} >
                                    <TabPane tabId="4" className="p-3">
                                        <p className="mb-0">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
                                                </p>
                                    </TabPane>
                                    <TabPane tabId="5" className="p-3" >
                                        <p className="mb-0">
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.
                                                </p>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Vertical Tabs</h4>
                                <div className="d-md-flex vertical_tabs">
                                    <Nav pills className='flex-column mr-mob-0 mr-4 mb-3 mb-sm-0'>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab2 === '6' })}
                                                onClick={() => { this.toggle2('6'); }}>
                                                <i className="ti-home"></i> Home
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab2 === '7' })}
                                                onClick={() => { this.toggle2('7'); }}>
                                                <i className="ti-user"></i> Profile
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab2 === '8' })}
                                                onClick={() => { this.toggle2('8'); }}>
                                                <i className="ti-mobile"></i> Contact
                                        </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={this.state.activeTab2}>
                                        <TabPane tabId="6">
                                            <p className="mb-0">
                                                Raw denim you probably haven't heard of them jean shorts Austin.
                                                Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache
                                                cliche tempor, williamsburg carles vegan helvetica. Reprehenderit
                                                butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,
                                                qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                                                iphone. Seitan aliquip quis cardigan american apparel, butcher
                                                voluptate nisi qui.
                                                </p>
                                        </TabPane>
                                        <TabPane tabId="7">
                                            <p className="mb-0">
                                                Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                                                single-origin coffee squid. Exercitation +1 labore velit, blog
                                                sartorial PBR leggings next level wes anderson artisan four loko
                                                farm-to-table craft beer twee. Qui photo booth letterpress,
                                                commodo enim craft beer mlkshk aliquip jean shorts ullamco ad
                                                vinyl cillum PBR. Homo nostrud organic, assumenda labore
                                                aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr,
                                                vero magna velit sapiente labore stumptown. Vegan fanny pack
                                                odio cillum wes anderson 8-bit.
                                                </p>
                                        </TabPane>
                                        <TabPane tabId="8">
                                            <p className="mb-0">
                                                Etsy mixtape wayfarers, ethical wes anderson tofu before they
                                                sold out mcsweeney's organic lomo retro fanny pack lo-fi
                                                farm-to-table readymade. Messenger bag gentrify pitchfork
                                                tattooed craft beer, iphone skateboard locavore carles etsy
                                                salvia banksy hoodie helvetica. DIY synth PBR banksy irony.
                                                Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                                                mi whatever gluten-free, carles pitchfork biodiesel fixie etsy
                                                retro mlkshk vice blog. Scenester cred you probably haven't
                                                heard of them, vinyl craft beer blog stumptown. Pitchfork
                                                sustainable tofu synth chambray yr.
                                                </p>
                                        </TabPane>
                                        <TabPane tabId="8">
                                            <p className="mb-0">
                                                Trust fund seitan letterpress, keytar raw denim keffiyeh etsy
                                                art party before they sold out master cleanse gluten-free squid
                                                scenester freegan cosby sweater. Fanny pack portland seitan DIY,
                                                art party locavore wolf cliche high life echo park Austin. Cred
                                                vinyl keffiyeh DIY salvia PBR, banh mi before they sold out
                                                farm-to-table VHS viral locavore cosby sweater. Lomo wolf viral,
                                                mustache readymade thundercats keffiyeh craft beer marfa
                                                ethical. Wolf salvia freegan, sartorial keffiyeh echo park
                                                vegan.
                                                </p>
                                        </TabPane>
                                    </TabContent>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Tabs With Pills</h4>
                                <Nav pills>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab3 === '9' })}
                                            onClick={() => { this.toggle3('9'); }}>
                                            <i className="ti-home"></i> Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab3 === '10' })}
                                            onClick={() => { this.toggle3('10'); }}>
                                            <i className="ti-user"></i> Profile
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab3 === '11' })}
                                            onClick={() => { this.toggle3('11'); }}>
                                            <i className="ti-mobile"></i> Contact
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={this.state.activeTab3}>
                                    <TabPane tabId="9" className="p-3 pl-0">
                                        <p className="mb-0">
                                            Raw denim you probably haven't heard of them jean shorts Austin.
                                            Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache
                                            cliche tempor, williamsburg carles vegan helvetica. Reprehenderit
                                            butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,
                                            qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                                            iphone. Seitan aliquip quis cardigan american apparel, butcher
                                            voluptate nisi qui.
                                                </p>
                                    </TabPane>
                                    <TabPane className="p-3 pl-0" tabId="10">
                                        <p className="mb-0">
                                            Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                                            single-origin coffee squid. Exercitation +1 labore velit, blog
                                            sartorial PBR leggings next level wes anderson artisan four loko
                                            farm-to-table craft beer twee. Qui photo booth letterpress,
                                            commodo enim craft beer mlkshk aliquip jean shorts ullamco ad
                                            vinyl cillum PBR. Homo nostrud organic, assumenda labore
                                            aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr,
                                            vero magna velit sapiente labore stumptown. Vegan fanny pack
                                            odio cillum wes anderson 8-bit.
                                                </p>
                                    </TabPane>
                                    <TabPane className="p-3 pl-0" tabId="11">
                                        <p className="mb-0">
                                            Etsy mixtape wayfarers, ethical wes anderson tofu before they
                                            sold out mcsweeney's organic lomo retro fanny pack lo-fi
                                            farm-to-table readymade. Messenger bag gentrify pitchfork
                                            tattooed craft beer, iphone skateboard locavore carles etsy
                                            salvia banksy hoodie helvetica. DIY synth PBR banksy irony.
                                            Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                                            mi whatever gluten-free, carles pitchfork biodiesel fixie etsy
                                            retro mlkshk vice blog. Scenester cred you probably haven't
                                            heard of them, vinyl craft beer blog stumptown. Pitchfork
                                            sustainable tofu synth chambray yr.
                                                </p>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Tabs));