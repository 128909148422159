import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input} from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class FormBasic extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Basic Form inputs</h4>
                                <p className="text-muted font-14 mb-4">Here are examples of <code
                                >.form-control</code> applied to each
                                            textual HTML5 <code>&lt;input&gt;</code> <code
                                    >type</code>.</p>
                                <Form>
                                    <FormGroup row>
                                        <Label htmlFor="example-text-input" sm="12">Name</Label>
                                        <Col sm="12">
                                            <Input type="text" defaultValue="Carlos Rath" id="example-text-input" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="example-email-input" sm="12">Email</Label>
                                        <Col sm="12">
                                            <Input type="email" defaultValue="name@example.com" id="example-email-input" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="example-tel-input" sm="12">Telephone</Label>
                                        <Col sm="12">
                                            <Input type="tel" defaultValue="+880-1233456789" id="example-tel-input" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="example-password-input" sm="12">Password</Label>
                                        <Col sm="12">
                                            <Input type="password" defaultValue="inputPassword" id="example-password-input" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="example-date-input" sm="12">Date</Label>
                                        <Col sm="12">
                                            <Input type="date" defaultValue="2020-07-19T15:30:00" id="example-date-input" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="12">Select</Label>
                                        <Col sm="12">
                                            <select className="form-control">
                                                <option>Select</option>
                                                <option>Large select</option>
                                                <option>Small select</option>
                                            </select>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="12">Custom Select</Label>
                                        <Col sm="12">
                                            <select className="custom-select">
                                                <option value="">Open this select menu</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Input Sizes</h4>
                                <Form>
                                    <FormGroup row>
                                        <Label htmlFor="example-text-input-lg" sm="12">Large</Label>
                                        <Col sm="12">
                                            <Input className="form-control form-control-lg" type="text" placeholder=".form-control-lg" id="example-text-input-lg" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="example-text-input-sm" sm="12">Small</Label>
                                        <Col sm="12">
                                            <Input className="form-control form-control-sm" type="text" placeholder=".form-control-sm" id="example-text-input-sm" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Form Control Feedback</h4>
                                <Form>
                                    <FormGroup row>
                                        <Label htmlFor="example-email-input" sm="12">Email</Label>
                                        <Col sm="12">
                                            <Input type="email" defaultValue="name@example.com" id="example-email-input" />
                                            <div className="form-control-feedback">Primary! You've done it.</div>
                                            <small className="form-text text-muted">Example help text that remains unchanged.</small>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="example-email-input" sm="12">Email</Label>
                                        <Col sm="12">
                                            <Input type="email" defaultValue="name@example.com" id="example-email-input" />
                                            <div className="form-control-feedback">Sorry, that username's taken. Try another?</div><small className="form-text text-muted">Example help text that remains unchanged.</small>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Radios</h4>
                                <p>
                                    For even more customization and cross browser consistency, use our completely custom form elements to replace the browser defaults. They’re built on top of semantic and accessible markup, so they’re solid replacements for any default form control.
                                </p>
                                <Form>
                                    <b className="text-muted mb-3 d-block">Radios:</b>
                                    <div className="custom-control custom-radio primary-radio">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio1" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio1">Checked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio primary-radio">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio2" />
                                        <Label className="custom-control-label" for="customRadio2">Unchecked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio primary-radio">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio3" checked disabled />
                                        <Label className="custom-control-label" for="customRadio3">Disabled Radio</Label>
                                    </div>
                                    <b className="text-muted mb-3 mt-4 d-block">Inline Radios:</b>
                                    <div className="custom-control custom-radio primary-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio4" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio4">Checked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio primary-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio5" />
                                        <Label className="custom-control-label" for="customRadio5">Unchecked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio primary-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio6" checked disabled />
                                        <Label className="custom-control-label" for="customRadio6">Disabled Radio</Label>
                                    </div>
                                    <b className="text-muted mb-3 mt-4 d-block">Colored Radios:</b>
                                    <div className="custom-control custom-radio primary-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio7" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio7">Checked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio secondary-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio8" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio8">Checked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio danger-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio9" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio9">Checked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio success-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio10" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio10">Checked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio warning-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio11" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio11">Checked Radio</Label>
                                    </div>
                                    <div className="custom-control custom-radio dark-radio custom-control-inline">
                                        <Input type="radio" className="custom-control-input"
                                            id="customRadio12" defaultChecked />
                                        <Label className="custom-control-label" for="customRadio12">Checked Radio</Label>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Checkboxes</h4>
                                <p>
                                For even more customization and cross browser consistency, use our completely custom form elements to replace the browser defaults. They’re built on top of semantic and accessible markup, so they’re solid replacements for any default form control.    
                                </p>
                                <Form>
                                    <b className="text-muted mb-3 d-block">Radios:</b>
                                    <div className="custom-control custom-checkbox primary-checkbox">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox1" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox1">Checked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox primary-checkbox">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox2" />
                                        <Label className="custom-control-label" for="customCheckbox2">Unchecked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox primary-checkbox">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox3" checked disabled />
                                        <Label className="custom-control-label" for="customCheckbox3">Disabled Checkboxe</Label>
                                    </div>
                                    <b className="text-muted mb-3 mt-4 d-block">Inline Checkboxes:</b>
                                    <div className="custom-control custom-checkbox primary-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox4" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox4">Checked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox primary-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox5" />
                                        <Label className="custom-control-label" for="customCheckbox5">Unchecked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox primary-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox6" checked disabled />
                                        <Label className="custom-control-label" for="customCheckbox6">Disabled Checkboxe</Label>
                                    </div>
                                    <b className="text-muted mb-3 mt-4 d-block">Colored Checkboxes:</b>
                                    <div className="custom-control custom-checkbox primary-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox7" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox7">Checked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox secondary-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox8" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox8">Checked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox danger-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox9" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox9">Checked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox success-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox10" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox10">Checked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox warning-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox11" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox11">Checked Checkboxe</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox dark-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input"
                                            id="customCheckbox12" defaultChecked />
                                        <Label className="custom-control-label" for="customCheckbox12">Checked Checkboxe</Label>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(FormBasic));