import React, { Component } from 'react';
import { Polar } from 'react-chartjs-2';

class PolarChart extends Component {

    render() {
        const data = {
            labels: ["Data 1", "Data 2", "Data 3", "Data 4"],
            datasets: [{
                backgroundColor: [
                    "#9768D1",
                    "#7B52AB",
                    "#553285",
                    "#36175E",
                    "#25064C",
                ],
                data: [12, 19, 3, 17]
            }]
        };

        const option = {
            maintainAspectRatio: false,

            legend: {
                display: true,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                }
            },
        }

        return (
            <React.Fragment>
                <Polar width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default PolarChart;   