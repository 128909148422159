import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LineChart from '../InitCharts/echart/linechart';
import LineBarChart from '../InitCharts/echart/linebarchart';
import DoughnutChart from '../InitCharts/echart/doughnutchart';
import PieChart from '../InitCharts/echart/piechart';
import ScatterChart from '../InitCharts/echart/scatterchart';
import BubbleChart from '../InitCharts/echart/bubblechart';

class EchartJs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Line Chart</h4>
                                <LineChart />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Mix Line-Bar</h4>
                                <LineBarChart />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Doughnut Chart</h4>
                                <DoughnutChart />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Pie Chart</h4>
                                <PieChart />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Scatter Chart</h4>
                                <ScatterChart />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Bubble Chart</h4>
                                <BubbleChart />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(EchartJs));