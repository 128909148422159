import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class FontAwesome extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">66 New Icons In 4.4</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-500px"></i> 500px</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-amazon"></i> amazon</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-balance-scale"></i>
                                    balance-scale</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-0"></i> battery-0
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-1"></i> battery-1
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-2"></i> battery-2
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-3"></i> battery-3
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-4"></i> battery-4
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-empty"></i>
                                    battery-empty</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-full"></i>
                                    battery-full</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-half"></i>
                                    battery-half</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-quarter"></i>
                                    battery-quarter</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-three-quarters"></i>
                                    battery-three-quarters</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-black-tie"></i> black-tie</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-check-o"></i>
                                    calendar-check-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-minus-o"></i>
                                    calendar-minus-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-plus-o"></i>
                                    calendar-plus-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-times-o"></i>
                                    calendar-times-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-diners-club"></i>
                                    cc-diners-club</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-jcb"></i> cc-jcb</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chrome"></i> chrome</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-clone"></i> clone</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-commenting"></i>
                                    commenting</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-commenting-o"></i>
                                    commenting-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-contao"></i> contao</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-creative-commons"></i>
                                    creative-commons</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-expeditedssl"></i>
                                    expeditedssl</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-firefox"></i> firefox</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fonticons"></i> fonticons</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-genderless"></i>
                                    genderless</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-get-pocket"></i>
                                    get-pocket</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gg"></i> gg</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gg-circle"></i> gg-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-grab-o"></i>
                                    hand-grab-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-lizard-o"></i>
                                    hand-lizard-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-paper-o"></i>
                                    hand-paper-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-peace-o"></i>
                                    hand-peace-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-pointer-o"></i>
                                    hand-pointer-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-rock-o"></i>
                                    hand-rock-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-scissors-o"></i>
                                    hand-scissors-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-spock-o"></i>
                                    hand-spock-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-stop-o"></i>
                                    hand-stop-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass"></i> hourglass</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-1"></i>
                                    hourglass-1 <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-2"></i>
                                    hourglass-2 <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-3"></i>
                                    hourglass-3 <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-end"></i>
                                    hourglass-end</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-half"></i>
                                    hourglass-half</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-o"></i>
                                    hourglass-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-start"></i>
                                    hourglass-start</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-houzz"></i> houzz</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-i-cursor"></i> i-cursor</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-industry"></i> industry</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-internet-explorer"></i>
                                    internet-explorer</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map"></i> map</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map-o"></i> map-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map-pin"></i> map-pin</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map-signs"></i> map-signs</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mouse-pointer"></i>
                                    mouse-pointer</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-object-group"></i>
                                    object-group</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-object-ungroup"></i>
                                    object-ungroup</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-odnoklassniki"></i>
                                    odnoklassniki</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-odnoklassniki-square"></i>
                                    odnoklassniki-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-opencart"></i> opencart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-opera"></i> opera</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-optin-monster"></i>
                                    optin-monster</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-registered"></i>
                                    registered</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-safari"></i> safari</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sticky-note"></i>
                                    sticky-note</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sticky-note-o"></i>
                                    sticky-note-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-television"></i>
                                    television</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-trademark"></i> trademark</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tripadvisor"></i>
                                    tripadvisor</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tv"></i> tv <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-vimeo"></i> vimeo</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wikipedia-w"></i>
                                    wikipedia-w</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-y-combinator"></i>
                                    y-combinator</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-yc"></i> yc <span className="text-muted">(alias)</span></Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Web Application Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-adjust"></i> adjust</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-anchor"></i> anchor</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-archive"></i> archive</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-area-chart"></i>
                                    area-chart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows"></i> arrows</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows-h"></i> arrows-h</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows-v"></i> arrows-v</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-asterisk"></i> asterisk</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-at"></i> at</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-automobile"></i>
                                    automobile <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-balance-scale"></i>
                                    balance-scale</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ban"></i> ban</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bank"></i> bank <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bar-chart"></i> bar-chart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bar-chart-o"></i>
                                    bar-chart-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-barcode"></i> barcode</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bars"></i> bars</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-0"></i> battery-0
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-1"></i> battery-1
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-2"></i> battery-2
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-3"></i> battery-3
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-4"></i> battery-4
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-empty"></i>
                                    battery-empty</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-full"></i>
                                    battery-full</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-half"></i>
                                    battery-half</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-quarter"></i>
                                    battery-quarter</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-battery-three-quarters"></i>
                                    battery-three-quarters</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bed"></i> bed</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-beer"></i> beer</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bell"></i> bell</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bell-o"></i> bell-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bell-slash"></i>
                                    bell-slash</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bell-slash-o"></i>
                                    bell-slash-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bicycle"></i> bicycle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-binoculars"></i>
                                    binoculars</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-birthday-cake"></i>
                                    birthday-cake</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bolt"></i> bolt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bomb"></i> bomb</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-book"></i> book</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bookmark"></i> bookmark</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bookmark-o"></i>
                                    bookmark-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-briefcase"></i> briefcase</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bug"></i> bug</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-building"></i> building</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-building-o"></i>
                                    building-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bullhorn"></i> bullhorn</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bullseye"></i> bullseye</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bus"></i> bus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cab"></i> cab <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calculator"></i>
                                    calculator</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar"></i> calendar</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-check-o"></i>
                                    calendar-check-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-minus-o"></i>
                                    calendar-minus-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-o"></i>
                                    calendar-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-plus-o"></i>
                                    calendar-plus-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-calendar-times-o"></i>
                                    calendar-times-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-camera"></i> camera</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-camera-retro"></i>
                                    camera-retro</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-car"></i> car</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-down"></i>
                                    caret-square-o-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-left"></i>
                                    caret-square-o-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-right"></i>
                                    caret-square-o-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-up"></i>
                                    caret-square-o-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cart-arrow-down"></i>
                                    cart-arrow-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cart-plus"></i> cart-plus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc"></i> cc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-certificate"></i>
                                    certificate</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-check"></i> check</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-check-circle"></i>
                                    check-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-check-circle-o"></i>
                                    check-circle-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-check-square"></i>
                                    check-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-check-square-o"></i>
                                    check-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-child"></i> child</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-circle"></i> circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-circle-o"></i> circle-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-circle-o-notch"></i>
                                    circle-o-notch</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-circle-thin"></i>
                                    circle-thin</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-clock-o"></i> clock-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-clone"></i> clone</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-close"></i> close
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cloud"></i> cloud</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cloud-download"></i>
                                    cloud-download</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cloud-upload"></i>
                                    cloud-upload</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-code"></i> code</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-code-fork"></i> code-fork</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-coffee"></i> coffee</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cog"></i> cog</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cogs"></i> cogs</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-comment"></i> comment</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-comment-o"></i> comment-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-commenting"></i>
                                    commenting</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-commenting-o"></i>
                                    commenting-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-comments"></i> comments</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-comments-o"></i>
                                    comments-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-compass"></i> compass</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-copyright"></i> copyright</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-creative-commons"></i>
                                    creative-commons</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-credit-card"></i>
                                    credit-card</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-crop"></i> crop</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-crosshairs"></i>
                                    crosshairs</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cube"></i> cube</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cubes"></i> cubes</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cutlery"></i> cutlery</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dashboard"></i> dashboard
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-database"></i> database</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-desktop"></i> desktop</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-diamond"></i> diamond</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dot-circle-o"></i>
                                    dot-circle-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-download"></i> download</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-edit"></i> edit <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ellipsis-h"></i>
                                    ellipsis-h</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ellipsis-v"></i>
                                    ellipsis-v</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-envelope"></i> envelope</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-envelope-o"></i>
                                    envelope-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-envelope-square"></i>
                                    envelope-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-eraser"></i> eraser</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-exchange"></i> exchange</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-exclamation"></i>
                                    exclamation</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-exclamation-circle"></i>
                                    exclamation-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-exclamation-triangle"></i>
                                    exclamation-triangle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-external-link"></i>
                                    external-link</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-external-link-square"></i>
                                    external-link-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-eye"></i> eye</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-eye-slash"></i> eye-slash</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-eyedropper"></i>
                                    eyedropper</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fax"></i> fax</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-feed"></i> feed <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-female"></i> female</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fighter-jet"></i>
                                    fighter-jet</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-archive-o"></i>
                                    file-archive-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-audio-o"></i>
                                    file-audio-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-code-o"></i>
                                    file-code-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-excel-o"></i>
                                    file-excel-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-image-o"></i>
                                    file-image-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-movie-o"></i>
                                    file-movie-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-pdf-o"></i>
                                    file-pdf-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-photo-o"></i>
                                    file-photo-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-picture-o"></i>
                                    file-picture-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-powerpoint-o"></i>
                                    file-powerpoint-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-sound-o"></i>
                                    file-sound-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-video-o"></i>
                                    file-video-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-word-o"></i>
                                    file-word-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-zip-o"></i>
                                    file-zip-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-film"></i> film</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-filter"></i> filter</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fire"></i> fire</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fire-extinguisher"></i>
                                    fire-extinguisher</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-flag"></i> flag</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-flag-checkered"></i>
                                    flag-checkered</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-flag-o"></i> flag-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-flash"></i> flash
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-flask"></i> flask</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-folder"></i> folder</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-folder-o"></i> folder-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-folder-open"></i>
                                    folder-open</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-folder-open-o"></i>
                                    folder-open-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-frown-o"></i> frown-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-futbol-o"></i> futbol-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gamepad"></i> gamepad</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gavel"></i> gavel</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gear"></i> gear <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gears"></i> gears
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gift"></i> gift</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-glass"></i> glass</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-globe"></i> globe</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-graduation-cap"></i>
                                    graduation-cap</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-group"></i> group
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-grab-o"></i>
                                    hand-grab-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-lizard-o"></i>
                                    hand-lizard-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-paper-o"></i>
                                    hand-paper-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-peace-o"></i>
                                    hand-peace-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-pointer-o"></i>
                                    hand-pointer-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-rock-o"></i>
                                    hand-rock-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-scissors-o"></i>
                                    hand-scissors-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-spock-o"></i>
                                    hand-spock-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-stop-o"></i>
                                    hand-stop-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hdd-o"></i> hdd-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-headphones"></i>
                                    headphones</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-heart"></i> heart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-heart-o"></i> heart-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-heartbeat"></i> heartbeat</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-history"></i> history</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-home"></i> home</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hotel"></i> hotel
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass"></i> hourglass</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-1"></i>
                                    hourglass-1 <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-2"></i>
                                    hourglass-2 <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-3"></i>
                                    hourglass-3 <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-end"></i>
                                    hourglass-end</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-half"></i>
                                    hourglass-half</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-o"></i>
                                    hourglass-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hourglass-start"></i>
                                    hourglass-start</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-i-cursor"></i> i-cursor</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-image"></i> image
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-inbox"></i> inbox</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-industry"></i> industry</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-info"></i> info</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-info-circle"></i>
                                    info-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-institution"></i>
                                    institution <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-key"></i> key</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-keyboard-o"></i>
                                    keyboard-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-language"></i> language</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-laptop"></i> laptop</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-leaf"></i> leaf</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-legal"></i> legal
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-lemon-o"></i> lemon-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-level-down"></i>
                                    level-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-level-up"></i> level-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-life-bouy"></i> life-bouy
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-life-buoy"></i> life-buoy
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-life-ring"></i> life-ring</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-life-saver"></i>
                                    life-saver <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-lightbulb-o"></i>
                                    lightbulb-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-line-chart"></i>
                                    line-chart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-location-arrow"></i>
                                    location-arrow</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-lock"></i> lock</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-magic"></i> magic</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-magnet"></i> magnet</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mail-forward"></i>
                                    mail-forward <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mail-reply"></i>
                                    mail-reply <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mail-reply-all"></i>
                                    mail-reply-all <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-male"></i> male</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map"></i> map</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map-marker"></i>
                                    map-marker</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map-o"></i> map-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map-pin"></i> map-pin</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-map-signs"></i> map-signs</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-meh-o"></i> meh-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-microphone"></i>
                                    microphone</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-microphone-slash"></i>
                                    microphone-slash</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-minus"></i> minus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-minus-circle"></i>
                                    minus-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-minus-square"></i>
                                    minus-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-minus-square-o"></i>
                                    minus-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mobile"></i> mobile</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mobile-phone"></i>
                                    mobile-phone <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-money"></i> money</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-moon-o"></i> moon-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mortar-board"></i>
                                    mortar-board <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-motorcycle"></i>
                                    motorcycle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mouse-pointer"></i>
                                    mouse-pointer</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-music"></i> music</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-navicon"></i> navicon
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-newspaper-o"></i>
                                    newspaper-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-object-group"></i>
                                    object-group</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-object-ungroup"></i>
                                    object-ungroup</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paint-brush"></i>
                                    paint-brush</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paper-plane"></i>
                                    paper-plane</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paper-plane-o"></i>
                                    paper-plane-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paw"></i> paw</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pencil"></i> pencil</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pencil-square"></i>
                                    pencil-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pencil-square-o"></i>
                                    pencil-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-phone"></i> phone</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-phone-square"></i>
                                    phone-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-photo"></i> photo
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-picture-o"></i> picture-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pie-chart"></i> pie-chart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plane"></i> plane</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plug"></i> plug</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plus"></i> plus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plus-circle"></i>
                                    plus-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plus-square"></i>
                                    plus-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plus-square-o"></i>
                                    plus-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-power-off"></i> power-off</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-print"></i> print</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-puzzle-piece"></i>
                                    puzzle-piece</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-qrcode"></i> qrcode</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-question"></i> question</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-question-circle"></i>
                                    question-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-quote-left"></i>
                                    quote-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-quote-right"></i>
                                    quote-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-random"></i> random</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-recycle"></i> recycle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-refresh"></i> refresh</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-registered"></i>
                                    registered</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-remove"></i> remove
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-reorder"></i> reorder
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-reply"></i> reply</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-reply-all"></i> reply-all</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-retweet"></i> retweet</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-road"></i> road</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rocket"></i> rocket</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rss"></i> rss</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rss-square"></i>
                                    rss-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-search"></i> search</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-search-minus"></i>
                                    search-minus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-search-plus"></i>
                                    search-plus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-send"></i> send <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-send-o"></i> send-o
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-server"></i> server</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-share"></i> share</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-share-alt"></i> share-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-share-alt-square"></i>
                                    share-alt-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-share-square"></i>
                                    share-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-share-square-o"></i>
                                    share-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-shield"></i> shield</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ship"></i> ship</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-shopping-cart"></i>
                                    shopping-cart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sign-in"></i> sign-in</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sign-out"></i> sign-out</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-signal"></i> signal</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sitemap"></i> sitemap</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sliders"></i> sliders</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-smile-o"></i> smile-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-soccer-ball-o"></i>
                                    soccer-ball-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort"></i> sort</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-alpha-asc"></i>
                                    sort-alpha-asc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-alpha-desc"></i>
                                    sort-alpha-desc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-amount-asc"></i>
                                    sort-amount-asc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-amount-desc"></i>
                                    sort-amount-desc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-asc"></i> sort-asc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-desc"></i> sort-desc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-down"></i> sort-down
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-numeric-asc"></i>
                                    sort-numeric-asc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-numeric-desc"></i>
                                    sort-numeric-desc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sort-up"></i> sort-up
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-space-shuttle"></i>
                                    space-shuttle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-spinner"></i> spinner</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-spoon"></i> spoon</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-square"></i> square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-square-o"></i> square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-star"></i> star</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-star-half"></i> star-half</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-star-half-empty"></i>
                                    star-half-empty <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-star-half-full"></i>
                                    star-half-full <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-star-half-o"></i>
                                    star-half-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-star-o"></i> star-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sticky-note"></i>
                                    sticky-note</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sticky-note-o"></i>
                                    sticky-note-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-street-view"></i>
                                    street-view</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-suitcase"></i> suitcase</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sun-o"></i> sun-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-support"></i> support
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tablet"></i> tablet</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tachometer"></i>
                                    tachometer</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tag"></i> tag</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tags"></i> tags</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tasks"></i> tasks</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-taxi"></i> taxi</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-television"></i>
                                    television</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-terminal"></i> terminal</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumb-tack"></i>
                                    thumb-tack</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-down"></i>
                                    thumbs-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-o-down"></i>
                                    thumbs-o-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-o-up"></i>
                                    thumbs-o-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-up"></i> thumbs-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ticket"></i> ticket</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-times"></i> times</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-times-circle"></i>
                                    times-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-times-circle-o"></i>
                                    times-circle-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tint"></i> tint</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-down"></i>
                                    toggle-down <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-left"></i>
                                    toggle-left <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-off"></i>
                                    toggle-off</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-on"></i> toggle-on</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-right"></i>
                                    toggle-right <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-up"></i> toggle-up
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-trademark"></i> trademark</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-trash"></i> trash</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-trash-o"></i> trash-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tree"></i> tree</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-trophy"></i> trophy</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-truck"></i> truck</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tty"></i> tty</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tv"></i> tv <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-umbrella"></i> umbrella</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-university"></i>
                                    university</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-unlock"></i> unlock</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-unlock-alt"></i>
                                    unlock-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-unsorted"></i> unsorted
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-upload"></i> upload</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-user"></i> user</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-user-plus"></i> user-plus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-user-secret"></i>
                                    user-secret</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-user-times"></i>
                                    user-times</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-users"></i> users</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-video-camera"></i>
                                    video-camera</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-volume-down"></i>
                                    volume-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-volume-off"></i>
                                    volume-off</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-volume-up"></i> volume-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-warning"></i> warning
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wheelchair"></i>
                                    wheelchair</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wifi"></i> wifi</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wrench"></i> wrench</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Hand Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-grab-o"></i>
                                    hand-grab-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-lizard-o"></i>
                                    hand-lizard-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-down"></i>
                                    hand-o-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-left"></i>
                                    hand-o-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-right"></i>
                                    hand-o-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-up"></i> hand-o-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-paper-o"></i>
                                    hand-paper-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-peace-o"></i>
                                    hand-peace-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-pointer-o"></i>
                                    hand-pointer-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-rock-o"></i>
                                    hand-rock-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-scissors-o"></i>
                                    hand-scissors-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-spock-o"></i>
                                    hand-spock-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-stop-o"></i>
                                    hand-stop-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-down"></i>
                                    thumbs-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-o-down"></i>
                                    thumbs-o-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-o-up"></i>
                                    thumbs-o-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-thumbs-up"></i> thumbs-up</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Transportation Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ambulance"></i> ambulance</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-automobile"></i>
                                    automobile <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bicycle"></i> bicycle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bus"></i> bus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cab"></i> cab <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-car"></i> car</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fighter-jet"></i>
                                    fighter-jet</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-motorcycle"></i>
                                    motorcycle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plane"></i> plane</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rocket"></i> rocket</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ship"></i> ship</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-space-shuttle"></i>
                                    space-shuttle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-subway"></i> subway</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-taxi"></i> taxi</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-train"></i> train</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-truck"></i> truck</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wheelchair"></i>
                                    wheelchair</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Gender Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-genderless"></i>
                                    genderless</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-intersex"></i> intersex
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mars"></i> mars</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mars-double"></i>
                                    mars-double</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mars-stroke"></i>
                                    mars-stroke</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mars-stroke-h"></i>
                                    mars-stroke-h</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mars-stroke-v"></i>
                                    mars-stroke-v</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-mercury"></i> mercury</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-neuter"></i> neuter</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-transgender"></i>
                                    transgender</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-transgender-alt"></i>
                                    transgender-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-venus"></i> venus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-venus-double"></i>
                                    venus-double</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-venus-mars"></i>
                                    venus-mars</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">File Type Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file"></i> file</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-archive-o"></i>
                                    file-archive-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-audio-o"></i>
                                    file-audio-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-code-o"></i>
                                    file-code-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-excel-o"></i>
                                    file-excel-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-image-o"></i>
                                    file-image-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-movie-o"></i>
                                    file-movie-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-o"></i> file-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-pdf-o"></i>
                                    file-pdf-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-photo-o"></i>
                                    file-photo-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-picture-o"></i>
                                    file-picture-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-powerpoint-o"></i>
                                    file-powerpoint-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-sound-o"></i>
                                    file-sound-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-text"></i> file-text</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-text-o"></i>
                                    file-text-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-video-o"></i>
                                    file-video-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-word-o"></i>
                                    file-word-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-zip-o"></i>
                                    file-zip-o <span className="text-muted">(alias)</span></Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Spinner Icons</h4>
                                <Row>
                                    <div className="col-md-12">
                                        <div className="alert alert-info">
                                            <ul className="fa-ul">
                                                <li><i className="fa fa-info-circle fa-lg fa-li"></i> These icons work great with the
                                        <code>fa-spin</code> class. Check out the
                                        <Link to="#" className="alert-link">spinning
                                            icons example</Link>.
                                    </li>
                                            </ul>
                                        </div>
                                        <Row>
                                            <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                                className="fa fa-circle-o-notch"></i>
                                    circle-o-notch</Link></div>
                                            <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                                className="fa fa-cog"></i> cog</Link></div>
                                            <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                                className="fa fa-gear"></i> gear <span
                                                    className="text-muted">(alias)</span></Link></div>
                                            <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                                className="fa fa-refresh"></i> refresh</Link></div>
                                            <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                                className="fa fa-spinner"></i> spinner</Link></div>
                                        </Row>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Form Control Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-check-square"></i>
                                    check-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-check-square-o"></i>
                                    check-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-circle"></i> circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-circle-o"></i> circle-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dot-circle-o"></i>
                                    dot-circle-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-minus-square"></i>
                                    minus-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-minus-square-o"></i>
                                    minus-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plus-square"></i>
                                    plus-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plus-square-o"></i>
                                    plus-square-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-square"></i> square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-square-o"></i> square-o</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Payment Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-amex"></i> cc-amex</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-diners-club"></i>
                                    cc-diners-club</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-discover"></i>
                                    cc-discover</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-jcb"></i> cc-jcb</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-mastercard"></i>
                                    cc-mastercard</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-paypal"></i> cc-paypal</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-stripe"></i> cc-stripe</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-visa"></i> cc-visa</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-credit-card"></i>
                                    credit-card</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-google-wallet"></i>
                                    google-wallet</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paypal"></i> paypal</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Chart Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-area-chart"></i>
                                    area-chart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bar-chart"></i> bar-chart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bar-chart-o"></i>
                                    bar-chart-o <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-line-chart"></i>
                                    line-chart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pie-chart"></i> pie-chart</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Currency Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bitcoin"></i> bitcoin
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-btc"></i> btc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cny"></i> cny <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dollar"></i> dollar
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-eur"></i> eur</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-euro"></i> euro <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gbp"></i> gbp</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gg"></i> gg</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gg-circle"></i> gg-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ils"></i> ils</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-inr"></i> inr</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-jpy"></i> jpy</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-krw"></i> krw</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-money"></i> money</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rmb"></i> rmb <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rouble"></i> rouble
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rub"></i> rub</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ruble"></i> ruble
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rupee"></i> rupee
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-shekel"></i> shekel
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sheqel"></i> sheqel
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-try"></i> try</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-turkish-lira"></i>
                                    turkish-lira <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-usd"></i> usd</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-won"></i> won <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-yen"></i> yen <span
                                            className="text-muted">(alias)</span></Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Text Editor Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-align-center"></i>
                                    align-center</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-align-justify"></i>
                                    align-justify</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-align-left"></i>
                                    align-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-align-right"></i>
                                    align-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bold"></i> bold</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chain"></i> chain
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chain-broken"></i>
                                    chain-broken</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-clipboard"></i> clipboard</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-columns"></i> columns</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-copy"></i> copy <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cut"></i> cut <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dedent"></i> dedent
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-eraser"></i> eraser</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file"></i> file</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-o"></i> file-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-text"></i> file-text</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-file-text-o"></i>
                                    file-text-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-files-o"></i> files-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-floppy-o"></i> floppy-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-font"></i> font</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-header"></i> header</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-indent"></i> indent</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-italic"></i> italic</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-link"></i> link</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-list"></i> list</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-list-alt"></i> list-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-list-ol"></i> list-ol</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-list-ul"></i> list-ul</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-outdent"></i> outdent</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paperclip"></i> paperclip</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paragraph"></i> paragraph</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paste"></i> paste
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-repeat"></i> repeat</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rotate-left"></i>
                                    rotate-left <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rotate-right"></i>
                                    rotate-right <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-save"></i> save <span
                                            className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-scissors"></i> scissors</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-strikethrough"></i>
                                    strikethrough</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-subscript"></i> subscript</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-superscript"></i>
                                    superscript</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-table"></i> table</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-text-height"></i>
                                    text-height</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-text-width"></i>
                                    text-width</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-th"></i> th</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-th-large"></i> th-large</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-th-list"></i> th-list</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-underline"></i> underline</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-undo"></i> undo</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-unlink"></i> unlink
                                    <span className="text-muted">(alias)</span></Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Directional Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-double-down"></i>
                                    angle-double-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-double-left"></i>
                                    angle-double-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-double-right"></i>
                                    angle-double-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-double-up"></i>
                                    angle-double-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-down"></i>
                                    angle-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-left"></i>
                                    angle-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-right"></i>
                                    angle-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angle-up"></i> angle-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-down"></i>
                                    arrow-circle-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-left"></i>
                                    arrow-circle-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-o-down"></i>
                                    arrow-circle-o-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-o-left"></i>
                                    arrow-circle-o-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-o-right"></i>
                                    arrow-circle-o-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-o-up"></i>
                                    arrow-circle-o-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-right"></i>
                                    arrow-circle-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-circle-up"></i>
                                    arrow-circle-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-down"></i>
                                    arrow-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-left"></i>
                                    arrow-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-right"></i>
                                    arrow-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrow-up"></i> arrow-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows"></i> arrows</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows-alt"></i>
                                    arrows-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows-h"></i> arrows-h</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows-v"></i> arrows-v</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-down"></i>
                                    caret-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-left"></i>
                                    caret-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-right"></i>
                                    caret-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-down"></i>
                                    caret-square-o-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-left"></i>
                                    caret-square-o-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-right"></i>
                                    caret-square-o-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-square-o-up"></i>
                                    caret-square-o-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-caret-up"></i> caret-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-circle-down"></i>
                                    chevron-circle-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-circle-left"></i>
                                    chevron-circle-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-circle-right"></i>
                                    chevron-circle-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-circle-up"></i>
                                    chevron-circle-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-down"></i>
                                    chevron-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-left"></i>
                                    chevron-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-right"></i>
                                    chevron-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chevron-up"></i>
                                    chevron-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-exchange"></i> exchange</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-down"></i>
                                    hand-o-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-left"></i>
                                    hand-o-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-right"></i>
                                    hand-o-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hand-o-up"></i> hand-o-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-long-arrow-down"></i>
                                    long-arrow-down</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-long-arrow-left"></i>
                                    long-arrow-left</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-long-arrow-right"></i>
                                    long-arrow-right</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-long-arrow-up"></i>
                                    long-arrow-up</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-down"></i>
                                    toggle-down <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-left"></i>
                                    toggle-left <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-right"></i>
                                    toggle-right <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-toggle-up"></i> toggle-up
                                    <span className="text-muted">(alias)</span></Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Video Player Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-arrows-alt"></i>
                                    arrows-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-backward"></i> backward</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-compress"></i> compress</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-eject"></i> eject</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-expand"></i> expand</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fast-backward"></i>
                                    fast-backward</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fast-forward"></i>
                                    fast-forward</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-forward"></i> forward</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pause"></i> pause</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-play"></i> play</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-play-circle"></i>
                                    play-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-play-circle-o"></i>
                                    play-circle-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-random"></i> random</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-step-backward"></i>
                                    step-backward</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-step-forward"></i>
                                    step-forward</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-stop"></i> stop</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-youtube-play"></i>
                                    youtube-play</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Brand Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-500px"></i> 500px</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-adn"></i> adn</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-amazon"></i> amazon</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-android"></i> android</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-angellist"></i> angellist</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-apple"></i> apple</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-behance"></i> behance</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-behance-square"></i>
                                    behance-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bitbucket"></i> bitbucket</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bitbucket-square"></i>
                                    bitbucket-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-bitcoin"></i> bitcoin
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-black-tie"></i> black-tie</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-btc"></i> btc</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-buysellads"></i>
                                    buysellads</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-amex"></i> cc-amex</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-diners-club"></i>
                                    cc-diners-club</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-discover"></i>
                                    cc-discover</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-jcb"></i> cc-jcb</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-mastercard"></i>
                                    cc-mastercard</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-paypal"></i> cc-paypal</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-stripe"></i> cc-stripe</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-cc-visa"></i> cc-visa</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-chrome"></i> chrome</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-codepen"></i> codepen</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-connectdevelop"></i>
                                    connectdevelop</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-contao"></i> contao</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-css3"></i> css3</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dashcube"></i> dashcube</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-delicious"></i> delicious</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-deviantart"></i>
                                    deviantart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-digg"></i> digg</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dribbble"></i> dribbble</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-dropbox"></i> dropbox</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-drupal"></i> drupal</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-empire"></i> empire</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-expeditedssl"></i>
                                    expeditedssl</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-facebook"></i> facebook</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-facebook-f"></i>
                                    facebook-f <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-facebook-official"></i>
                                    facebook-official</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-facebook-square"></i>
                                    facebook-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-firefox"></i> firefox</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-flickr"></i> flickr</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-fonticons"></i> fonticons</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-forumbee"></i> forumbee</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-foursquare"></i>
                                    foursquare</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ge"></i> ge <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-get-pocket"></i>
                                    get-pocket</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gg"></i> gg</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gg-circle"></i> gg-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-git"></i> git</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-git-square"></i>
                                    git-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-github"></i> github</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-github-alt"></i>
                                    github-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-github-square"></i>
                                    github-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gittip"></i> gittip
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-google"></i> google</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-google-plus"></i>
                                    google-plus</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-google-plus-square"></i>
                                    google-plus-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-google-wallet"></i>
                                    google-wallet</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-gratipay"></i> gratipay</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hacker-news"></i>
                                    hacker-news</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-houzz"></i> houzz</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-html5"></i> html5</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-instagram"></i> instagram</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-internet-explorer"></i>
                                    internet-explorer</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ioxhost"></i> ioxhost</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-joomla"></i> joomla</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-jsfiddle"></i> jsfiddle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-lastfm"></i> lastfm</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-lastfm-square"></i>
                                    lastfm-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-leanpub"></i> leanpub</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-linkedin"></i> linkedin</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-linkedin-square"></i>
                                    linkedin-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-linux"></i> linux</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-maxcdn"></i> maxcdn</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-meanpath"></i> meanpath</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-medium"></i> medium</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-odnoklassniki"></i>
                                    odnoklassniki</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-odnoklassniki-square"></i>
                                    odnoklassniki-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-opencart"></i> opencart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-openid"></i> openid</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-opera"></i> opera</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-optin-monster"></i>
                                    optin-monster</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pagelines"></i> pagelines</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-paypal"></i> paypal</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pied-piper"></i>
                                    pied-piper</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pied-piper-alt"></i>
                                    pied-piper-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pinterest"></i> pinterest</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pinterest-p"></i>
                                    pinterest-p</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-pinterest-square"></i>
                                    pinterest-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-qq"></i> qq</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ra"></i> ra <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-rebel"></i> rebel</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-reddit"></i> reddit</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-reddit-square"></i>
                                    reddit-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-renren"></i> renren</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-safari"></i> safari</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-sellsy"></i> sellsy</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-share-alt"></i> share-alt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-share-alt-square"></i>
                                    share-alt-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-shirtsinbulk"></i>
                                    shirtsinbulk</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-simplybuilt"></i>
                                    simplybuilt</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-skyatlas"></i> skyatlas</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-skype"></i> skype</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-slack"></i> slack</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-slideshare"></i>
                                    slideshare</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-soundcloud"></i>
                                    soundcloud</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-spotify"></i> spotify</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-stack-exchange"></i>
                                    stack-exchange</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-stack-overflow"></i>
                                    stack-overflow</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-steam"></i> steam</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-steam-square"></i>
                                    steam-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-stumbleupon"></i>
                                    stumbleupon</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-stumbleupon-circle"></i>
                                    stumbleupon-circle</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tencent-weibo"></i>
                                    tencent-weibo</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-trello"></i> trello</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tripadvisor"></i>
                                    tripadvisor</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tumblr"></i> tumblr</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-tumblr-square"></i>
                                    tumblr-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-twitch"></i> twitch</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-twitter"></i> twitter</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-twitter-square"></i>
                                    twitter-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-viacoin"></i> viacoin</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-vimeo"></i> vimeo</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-vimeo-square"></i>
                                    vimeo-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-vine"></i> vine</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-vk"></i> vk</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wechat"></i> wechat
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-weibo"></i> weibo</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-weixin"></i> weixin</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-whatsapp"></i> whatsapp</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wikipedia-w"></i>
                                    wikipedia-w</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-windows"></i> windows</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wordpress"></i> wordpress</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-xing"></i> xing</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-xing-square"></i>
                                    xing-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-y-combinator"></i>
                                    y-combinator</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-y-combinator-square"></i>
                                    y-combinator-square <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-yahoo"></i> yahoo</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-yc"></i> yc <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-yc-square"></i> yc-square
                                    <span className="text-muted">(alias)</span></Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-yelp"></i> yelp</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-youtube"></i> youtube</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-youtube-play"></i>
                                    youtube-play</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-youtube-square"></i>
                                    youtube-square</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Medical Icons</h4>
                                <Row>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-ambulance"></i> ambulance</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-h-square"></i> h-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-heart"></i> heart</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-heart-o"></i> heart-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-heartbeat"></i> heartbeat</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-hospital-o"></i>
                                    hospital-o</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-medkit"></i> medkit</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-plus-square"></i>
                                    plus-square</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-stethoscope"></i>
                                    stethoscope</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-user-md"></i> user-md</Link></div>
                                    <div className="fw-icons col-lg-3 col-sm-6"><Link to="#"><i
                                        className="fa fa-wheelchair"></i>
                                    wheelchair</Link></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(FontAwesome));