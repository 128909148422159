import React, { Component, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageForCrop from "../../assets/images/blog-listing/03.jpg";

const Demo = () => {
    const [image] = useState(imageForCrop);
    return (
        <div>
            <Row>
                <Col lg="12" className="stretched_card">
                    <Card>
                        <CardBody>
                            <h4 className="card_title">IMAGE CROPPER</h4>
                            <Row>
                                <Col md="9">
                                    <Cropper
                                        style={{ height: 500, width: "100%" }}
                                        initialAspectRatio={16 / 9}
                                        preview=".img-preview"
                                        src={image}
                                        viewMode={1}
                                        guides={true}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    />
                                </Col>
                                <Col md="3">
                                    <div
                                        className="img-preview preview-lg img-fluid"
                                        style={{ width: "100%", float: "left", height: "300px" }}
                                    />
                                    <div
                                        className="img-preview preview-md img-fluid"
                                        style={{ float: "left", height: "300px" }}
                                    />
                                    <div
                                        className="img-preview preview-sm img-fluid"
                                        style={{ float: "left", height: "300px" }}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

class CropperImage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }
    render() {
        return (
            <React.Fragment>
                <Demo {...this.props} />

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(CropperImage));