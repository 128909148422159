import React, { Component } from 'react';
import C3Chart from 'react-c3js';

class stacked extends Component {
    render() {
        var stackedData = {
            columns: [
                ['data1', 130, 200, 320, 400, 530, 750],
                ['data2', -130, 10, 130, 200, 150, 250],
                ['data3', -130, -50, -10, -200, -250, -150]
            ],
            type: 'bar',
            groups: [
                ['data1', 'data2', 'data3']
            ],
            order: 'desc', // stack order by sum of values descendantly. this is default.
            //      order: 'asc'  // stack order by sum of values ascendantly.
            //      order: null   // stack order by data definition.

            colors: {
                data1: '#9768D1',
                data2: '#7B52AB',
                data3: '#553285'

            }
        }
        var gridOption = {
            y: {
                lines: [{ value: 0 }]
            }
        }
        var axisOptions = {
            y: {
                show: true
            },
            x: {
                show: true
            }
        }

        return (
            <React.Fragment>
                <C3Chart data={stackedData} axis={axisOptions} grid={gridOption} />
            </React.Fragment>
        );
    }
}

export default stacked;