import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class InvoicePage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="stretched_card">
                        <Card>
                            <CardBody>
                                <div className="invoice-area">
                                    <div className="invoice-head">
                                        <div className="row">
                                            <div className="iv-left col-lg-6">
                                                <span>INVOICE</span>
                                            </div>
                                            <div className="iv-right col-lg-6 text-md-right">
                                                <span>#34445998</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="invoice-address">
                                                <h3>Invoiced to</h3>
                                                <h5>William T. Smith</h5>
                                                <p>
                                                    9172 West Bay Meadows Street
                                                <br />
                                                        Pickerington
                                                <br />
                                                            OH 43147
                                            </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 text-md-right">
                                            <ul className="invoice-date">
                                                <li>Invoice Date : sat 18-07-2019</li>
                                                <li>Due Date : sat 18-07-2019</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="invoice-table table-responsive mt-5">
                                        <table className="table table-bordered table-hover text-right">
                                            <thead>
                                                <tr className="text-capitalize">
                                                    <th className="text-center">id</th>
                                                    <th className="text-left">description</th>
                                                    <th>qty</th>
                                                    <th>Unit Cost</th>
                                                    <th>total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center">1</td>
                                                    <td className="text-left">Crazy Toys</td>
                                                    <td>1</td>
                                                    <td>$20</td>
                                                    <td>$40</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">2</td>
                                                    <td className="text-left">Beautiful flowers</td>
                                                    <td>2</td>
                                                    <td>$50</td>
                                                    <td>$100</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="4">total balance :</td>
                                                    <td>$140</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="invoice-buttons text-right">
                                    <Link to="#" className="btn btn-primary d-block-mob">print invoice</Link>
                                    <Link to="#" className="btn btn-primary mt-mob-4 d-block-mob">send invoice</Link>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(InvoicePage));