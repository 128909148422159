import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Alerts extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">alerts</h4>
                                <Alert color="primary">
                                    <strong>Well done!</strong> You successfully read this important alert message.
                                    </Alert>
                                <Alert color="success">
                                    <strong>Well done!</strong> You successfully read this important alert message.
                                    </Alert>
                                <Alert color="danger">
                                    <strong>Oh snap!</strong> Change a few things up and try submitting again.
                                    </Alert>
                                <Alert color="warning">
                                    <strong>Warning!</strong> Better check yourself, you're not looking too good.
                                    </Alert>
                                <Alert color="info">
                                    <strong>Heads up!</strong> This alert needs your attention, but it's not super important.
                                    </Alert>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">alert link</h4>
                                <Alert color="primary">
                                    <strong>Heads up!</strong> This <Link to="/#" className="alert-link">alert needs your attention, </Link>but it's not super.
                                    </Alert>
                                <Alert color="success">
                                    <strong>Well done!</strong> You <Link to="/#" className="alert-link">successfully read this important</Link> alert message.
                                    </Alert>
                                <Alert color="danger">
                                    <strong>Oh snap!</strong> Change a few things up and try <Link to="/#" className="alert-link">submitting again. </Link>
                                </Alert>
                                <Alert color="warning">
                                    <strong>Warning!</strong> Better <Link to="/#" className="alert-link">check yourself</Link>, you're not looking too good.
                                    </Alert>
                                <Alert color="info">
                                    <strong>Notice!</strong> Your info message text is <Link to="/#" className="alert-link">here.</Link>
                                </Alert>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Additional Content</h4>
                                <Alert color="primary">
                                    <h4 className="alert-heading">Well done!</h4>
                                    <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p>
                                    <hr />
                                    <p className="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
                                </Alert>
                                <Alert color="danger">
                                    <h4 className="alert-heading">Well done!</h4>
                                    <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p>
                                    <hr />
                                    <p className="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
                                </Alert>
                                <Alert color="warning">
                                    <h4 className="alert-heading">Well done!</h4>
                                    <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p>
                                    <hr />
                                    <p className="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
                                </Alert>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Dismissing</h4>
                                <div className="alert-dismiss">
                                    <Alert color="primary" isOpen={this.state.alert1} toggle={() => this.setState({ alert1: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                    <Alert color="danger" isOpen={this.state.alert2} toggle={() => this.setState({ alert2: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                    <Alert color="warning" isOpen={this.state.alert3} toggle={() => this.setState({ alert3: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                    <Alert color="info" isOpen={this.state.alert4} toggle={() => this.setState({ alert4: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                    <Alert color="dark" isOpen={this.state.alert5} toggle={() => this.setState({ alert5: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                    <Alert color="light" isOpen={this.state.alert6} toggle={() => this.setState({ alert6: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                    <Alert color="success" isOpen={this.state.alert7} toggle={() => this.setState({ alert7: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                    <Alert color="primary" isOpen={this.state.alert8} toggle={() => this.setState({ alert8: false })}>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields. Because this is Awesome.
                                        </Alert>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Alerts));