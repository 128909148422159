import React, { Component } from 'react';
import C3Chart from 'react-c3js';

class zoom extends Component {
    render() {
        var zoomData = {
            columns: [
                ['sample', 30, 200, 100, 400, 150, 250, 150, 200, 170, 240, 350, 150, 100, 400, 150, 250, 150, 200, 170, 240, 100, 150, 250, 150, 200, 170, 240, 30, 200, 100, 400, 150, 250, 150, 200, 170, 240, 350, 150, 100, 400, 350, 220, 250, 300, 270, 140, 150, 90, 150, 50, 120, 70, 40]
            ],
            colors: {
                sample: '#7B52AB'
            }
        }
        var zoomOption = {
            enabled: true
        }
        var axisOptions = {
            y: {
                show: true
            },
            x: {
                show: true
            }
        }

        return (
            <React.Fragment>
                <C3Chart data={zoomData} axis={axisOptions} zoom={zoomOption} />
            </React.Fragment>
        );
    }
}

export default zoom;