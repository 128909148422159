import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import Vector from './Vectormap';
import { connect } from 'react-redux';


class VectorMap extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">World Map</h4>
                                <Vector value="world_mill" width="500" color="#4327B7" />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">USA Map</h4>
                                <Vector value="us_aea" width="500" color="#4327B7" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Canada Map</h4>
                                <Vector value="ca_lcc" width="500" color="#4327B7" />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Asia Vector Map</h4>
                                <Vector value="asia_mill" width="500" color="#4327B7" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(VectorMap));