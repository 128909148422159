import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import image_3 from "../../assets/images/blog-listing/03.jpg";
import image_4 from "../../assets/images/blog-listing/04.jpg";
import post_1 from "../../assets/images/blog/post-1.jpg";
import member_1 from "../../assets/images/team/member1.jpg";

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="mb-4">
                        <div className="card_with_image">
                            <div className="blog_card_image">
                                <Link to="#">
                                    <img src={image_3} alt="" className="img-responsive " />
                                </Link>
                            </div>
                            <div className="rt_post_data">
                                <div className="blog_card_date">
                                    <h3>25</h3>
                                    <p>APR</p>
                                    <div className="blog_chat_icon">
                                        <ul>
                                            <li>
                                                <i className="icon-chat"></i>
                                                <span>5</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog_card_description">
                                    <div className="blog_data">
                                        <h3>There are many variations of passages of Lorem</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardbook.</p>
                                        <Link to="#" className="btn btn-primary">
                                            Read More
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg="6" className="mb-4">
                        <div className="card_with_image">
                            <div className="blog_card_image">
                                <Link to="#">
                                    <img src={image_4} alt="" className="img-responsive " />
                                </Link>
                            </div>
                            <div className="rt_post_data">
                                <div className="blog_card_date">
                                    <h3>25</h3>
                                    <p>APR</p>
                                    <div className="blog_chat_icon">
                                        <ul>
                                            <li>
                                                <i className="icon-chat"></i>
                                                <span>5</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog_card_description">
                                    <div className="blog_data">
                                        <h3>There are many variations of passages of Lorem</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standardbook.</p>
                                        <Link to="#" className="btn btn-primary">
                                            Read More
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="grid-masonry">
                    <Col md="4" sm="12" className="mb-4 grid-item">
                        <div className="image_card">
                            <div className="card_img_hover">
                                <div className="card_info">
                                    <svg className="card_clock" viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span className="card_date">15 - Dec</span>
                                </div>

                            </div>
                            <div className="card_img" style={{ backgroundImage: "url(" + post_1 + ")" }}></div>
                            <Link to="#" className="card_link">
                                <div className="card_img_hover_sec" style={{ backgroundImage: "url(" + post_1 + ")" }}></div>
                            </Link>
                            <div className="card__info">
                                <h3 className="card_title">Crisp Spanish tortilla Matzo brei</h3>
                                <span className="card_by">by <Link to="#" className="card_author" title="author">Celeste Mills</Link></span>
                            </div>
                        </div>
                    </Col>

                    <Col md="4" sm="12" className="mb-4 grid-item">
                        <div className="team_member">
                            <img src={member_1} alt="Team Member" />
                            <div className="member_name">
                                <h3>JHON DOE</h3>
                                <span>CEO/Founder</span>
                            </div>
                            <p>It is a long established fact that a reader will be distracted by the readable content distracted by the readable content.</p>
                            <ul>
                                <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                                <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                <li><Link to="#"><i className="fa fa-google-plus"></i></Link></li>
                                <li><Link to="#"><i className="fa fa-linkedin"></i></Link></li>
                            </ul>
                        </div>
                    </Col>

                    <Col md="4" sm="12" className="mb-4 grid-item">
                        <figure className="article_card">
                            <div className="image"><img src={image_4} alt="" /></div>
                            <figcaption>
                                <h5>Food</h5>
                                <h3>
                                    <Link to="#">
                                        Everything has beauty, but not everyone sees it
                                    </Link>
                                </h3>
                                <footer>
                                    <div className="date">October 30, 2015</div>
                                    <div className="icons">
                                        <div className="views"><i className="ion-eye"></i>2,907</div>
                                        <div className="love"><i className="ion-heart"></i>623</div>
                                    </div>
                                </footer>
                            </figcaption>
                        </figure>
                    </Col>
                </Row>

                <Row className="grid-masonry">
                    <Col md="12" lg="6">
                        <div className="card text-white bg-card-primary">
                            <div className="card-body">
                                <h4 className="">Background Primary Card</h4>
                                <hr />
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <Link to="#" className="btn btn-light">
                                    Learn More
                            </Link>
                            </div>
                        </div>
                        <div className="card text-white bg-card-secondary mt-3">
                            <div className="card-body">
                                <h4 className="">Background Success Card</h4>
                                <hr />
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title.</p>
                                <Link to="#" className="btn btn-light">
                                    Learn More
                            </Link>
                            </div>
                        </div>
                    </Col>

                    <Col md="12" lg="6" className="mt-mob-4">
                        <Row>
                            <div className="col-md-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-download"></i>
                                        <p>Total Downloads</p>
                                        <span>670</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-mob-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-shopping-cart-full"></i>
                                        <p>Products Sold</p>
                                        <span>9869</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-mob-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-user"></i>
                                        <p>Total users</p>
                                        <span>4567</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-image"></i>
                                        <p>Total Images</p>
                                        <span>4568</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-stats-up"></i>
                                        <p>Statics</p>
                                        <span>9873</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-cup"></i>
                                        <p>Achievements</p>
                                        <span>6543</span>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-6 mt-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-check-box"></i>
                                        <p>Approvals</p>
                                        <span>45676</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-server"></i>
                                        <p>Space Left</p>
                                        <span>4567</span>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Cards));