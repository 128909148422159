import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class RecentSaleChart extends Component {

    render() {
        const data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
            datasets: [{
                label: 'Europe',
                data: [345, 454, 690, 237, 700, 600],
                backgroundColor: '#4908FA'
            },
            {
                label: 'USA',
                data: [950, 900, 500, 620, 1020, 1200],
                backgroundColor: '#eaf0ff'
            }
            ]
        };

        const option = {
            responsive: true,
            maintainAspectRatio: true,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 20,
                    bottom: 0
                }
            },
            scales: {
                yAxes: [{
                    display: false,
                    gridLines: {
                        drawBorder: false,
                    },
                    ticks: {
                        stepSize: 250,
                        fontColor: "#686868"
                    }
                }],
                xAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        fontColor: "#686868"
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    barPercentage: 0.4
                }]
            },
            legend: {
                display: false
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            legendCallback: function(chart) {
                var text = [];
                text.push('<ul class="legend'+ chart.id +'">');
                for (var i = 0; i < chart.data.datasets.length; i++) {
                    text.push('<li><span class="legend-dots" style="background-color:' + chart.data.datasets[i].backgroundColor + '"></span>');
                    if (chart.data.datasets[i].label) {
                        text.push(chart.data.datasets[i].label);
                    }
                    text.push('</li>');
                }
                text.push('</ul>');
                return text.join("");
            }
        }

        return (
            <React.Fragment>
                <Bar width={600} height={290} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default RecentSaleChart;   