import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label, Button, Input, ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Compose extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <h3>
                                    Write Email
                                </h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="3" sm="12">
                        <Card>
                            <CardBody>
                                <Link to="/compose" className="btn btn-primary btn-block">Compose</Link>
                                <ul className="list-unstyled mail_tabs">
                                    <li className="active">
                                        <Link to="/#">
                                            <i className="fa fa-inbox"></i> Inbox <span
                                                className="badge badge-primary pull-right">6</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-send-o"></i> Sent
                                    </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-edit"></i> Drafts <span
                                                className="badge badge-accent pull-right">2</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-star-o"></i> Important
                                    </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-trash-o"></i> Trash
                                    </Link>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="9" sm="12" className="mt-mob-4">
                        <Card>
                            <CardBody>
                                <div className="mail_content">
                                    <Row>
                                        <div className="col-lg-12">
                                            <h3 className="mail_head">Compose Message </h3>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="pull-right all_mails_btn">
                                                <div className="btn-group btn-split mail_more_btn mt-0">
                                                    <ButtonDropdown isOpen={this.state.dropdLink} toggle={() => this.setState({ dropdLink: !this.state.dropdLink })} className="mb-3 mr-2 btn-split">
                                                        <Button id="caret" color="primary">Options</Button>
                                                        <DropdownToggle caret color="primary" />
                                                        <DropdownMenu>
                                                            <DropdownItem>Dropdown</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                            <DropdownItem divider />
                                                            <DropdownItem>Separated link</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mail_message col-lg-12">
                                            <form action="/">
                                                <div className="form-group">
                                                    <Label for="example-email-input" className="col-form-label">To:</Label>
                                                    <Input className="form-control" type="email" placeholder="name@example.com" id="example-email-input" />
                                                </div>
                                                <div className="form-group">
                                                    <Label for="example-text-input" className="col-form-label">Subject</Label>
                                                    <Input className="form-control" type="text" placeholder="Carlos Rath" id="example-text-input" />
                                                </div>
                                                <div className="form-group">
                                                    <Label for="example-text-input" className="col-form-label">Compose Message</Label>
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName" />
                                                </div>
                                            </form>
                                        </div>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Compose));