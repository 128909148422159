import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, CustomInput } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class FormGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Inline form</h4>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>@</InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="username" />
                                </InputGroup>
                                <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <Input addon type="checkbox" aria-label="Checkbox for following text input" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Check it out" />
                                </InputGroup>
                                <br />
                                <InputGroup>
                                    <Input placeholder="username" />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>@example.com</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Dolla dolla billz yo!" />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>$</InputGroupText>
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                    <Input placeholder="Amount" min={0} max={100} type="number" step="1" />
                                    <InputGroupAddon addonType="append">.00</InputGroupAddon>
                                </InputGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">CUSTOM FILE INPUT</h4>
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleCustomFileBrowser">File Browser</Label>
                                        <CustomInput type="file" id="exampleCustomFileBrowser" name="customFile" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleCustomFileBrowser1">File Browser with Custom Label</Label>
                                        <CustomInput type="file" id="exampleCustomFileBrowser1" name="customFile" label="Yo, pick a file!" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleCustomFileBrowser3">File Browser Disabled</Label>
                                        <CustomInput type="file" id="exampleCustomFileBrowser3" name="customFile" disabled />
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(FormGroups));