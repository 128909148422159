import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, Badge, Nav, NavItem, NavLink } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Badges extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Notifications Badges</h4>
                                <Button type="button" color="primary" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                                <Button type="button" color="secondary" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                                <Button type="button" color="success" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                                <Button type="button" color="info" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                                <Button type="button" color="warning" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                                <Button type="button" color="danger" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                                <Button type="button" color="dark" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                                <Button type="button" color="light" className="mb-3 mr-1">Notifications <Badge color="light">4</Badge></Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Profile Badges</h4>
                                <Button type="button" color="primary" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                                <Button type="button" color="secondary" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                                <Button type="button" color="success" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                                <Button type="button" color="info" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                                <Button type="button" color="warning" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                                <Button type="button" color="danger" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                                <Button type="button" color="dark" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                                <Button type="button" color="light" className="mb-3 mr-1">Profile <Badge color="light">4</Badge></Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" xl="4" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Contextual variations</h4>
                                <span><Badge className="mb-3 mr-2" color="primary">Primary</Badge></span>
                                <span><Badge className="mb-3 mr-2" color="secondary">Secondary</Badge></span>
                                <span><Badge className="mb-3 mr-2" color="success">Success</Badge></span>
                                <span><Badge className="mb-3 mr-2" color="danger">Danger</Badge></span>
                                <span><Badge className="mb-3 mr-2" color="warning">Warning</Badge></span>
                                <span><Badge className="mb-3 mr-2" color="info">Info</Badge></span>
                                <span><Badge className="mb-3 mr-2" color="light">Light</Badge></span>
                                <span><Badge className="mb-3 mr-2" color="dark">Dark</Badge></span>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" xl="4" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Pill badges</h4>
                                <span><Badge className="mb-3 mr-2" pill color="primary">Primary</Badge></span>
                                <span><Badge className="mb-3 mr-2" pill color="secondary">Secondary</Badge></span>
                                <span><Badge className="mb-3 mr-2" pill color="success">Success</Badge></span>
                                <span><Badge className="mb-3 mr-2" pill color="danger">Danger</Badge></span>
                                <span><Badge className="mb-3 mr-2" pill color="warning">Warning</Badge></span>
                                <span><Badge className="mb-3 mr-2" pill color="info">Info</Badge></span>
                                <span><Badge className="mb-3 mr-2" pill color="light">Light</Badge></span>
                                <span><Badge className="mb-3 mr-2" pill color="dark">Dark</Badge></span>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" xl="4" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Links badges</h4>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="primary">Primary</Badge></Link>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="secondary">Secondary</Badge></Link>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="success">Success</Badge></Link>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="danger">Danger</Badge></Link>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="warning">Warning</Badge></Link>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="info">Info</Badge></Link>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="light">Light</Badge></Link>
                                <Link to="#"><Badge className="mb-3 mr-2" pill color="dark">Dark</Badge></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Nav Pills</h4>
                                <Nav className="nav-pills">
                                    <NavItem>
                                        <NavLink href="#" className="active">Active</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">Link</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">Link</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink disabled href="#">Disabled Link</NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Fill and justify</h4>
                                <Nav className="nav-pills nav-fill">
                                    <NavItem>
                                        <NavLink href="#" className="active">Active</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">Link</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">Link</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink disabled href="#">Disabled Link</NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Badges));