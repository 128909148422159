import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Listgroup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">BASIC LIST GROUP</h4>
                                <ListGroup>
                                    <ListGroupItem tag="li">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="li">Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="li">Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="li">Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="li">Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">ACTIVE ITEMS</h4>
                                <ListGroup>
                                    <ListGroupItem tag="li" className="active">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="li">Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="li">Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="li">Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="li">Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Links And Buttons</h4>
                                <ListGroup>
                                    <ListGroupItem tag="a" href="#" className="active list-group-item-action">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action">Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action">Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action">Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action">Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Disabled Items</h4>
                                <ListGroup>
                                    <ListGroupItem tag="li" className="disabled">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="li">Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="li">Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="li">Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="li">Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Buttons Items</h4>
                                <ListGroup>
                                    <ListGroupItem tag="button" className="active list-group-item-action">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="button" className="list-group-item-action">Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="button" className="list-group-item-action">Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="button" className="list-group-item-action">Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="button" className="list-group-item-action">Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Flush</h4>
                                <ListGroup className="list-group-flush">
                                    <ListGroupItem tag="li" >Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="li" >Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="li" >Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="li" >Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="li" >Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Contextual Classes</h4>
                                <ListGroup>
                                    <ListGroupItem tag="li">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-primary">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-secondary ">Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-success">Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-danger">Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-warning">Vestibulum at eros</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-info">Vestibulum at eros</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-light">Vestibulum at eros</ListGroupItem>
                                    <ListGroupItem tag="li" className="list-group-item-dark">Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Contextual Classes Links</h4>
                                <ListGroup>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action list-group-item-primary">Cras justo odio</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action list-group-item-secondary ">Dapibus ac facilisis in</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action list-group-item-success">Morbi leo risus</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action list-group-item-danger">Porta ac consectetur ac</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action list-group-item-warning">Vestibulum at eros</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action list-group-item-info">Vestibulum at eros</ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item-action list-group-item-light">Vestibulum at eros</ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">With Badges</h4>
                                <ListGroup>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center">Cras justo odio <span className="badge badge-primary badge-pill">14</span></ListGroupItem>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center">Cras justo odio <span className="badge badge-secondary badge-pill">15</span></ListGroupItem>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center ">Dapibus ac facilisis in <span className="badge badge-success badge-pill">11</span></ListGroupItem>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center">Morbi leo risus <span className="badge badge-warning badge-pill">18</span></ListGroupItem>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center">Porta ac consectetur ac <span className="badge badge-danger badge-pill">53</span></ListGroupItem>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center">Vestibulum at eros <span className="badge badge-info badge-pill">57</span></ListGroupItem>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center">Vestibulum at eros <span className="badge badge-light badge-pill">84</span></ListGroupItem>
                                    <ListGroupItem tag="li" className="d-flex justify-content-between align-items-center">Vestibulum at eros <span className="badge badge-dark badge-pill">64</span></ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Custom Content</h4>
                                <ListGroup>
                                    <ListGroupItem tag="a" href="#" className="list-group-item list-group-item-action flex-column align-items-start active">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">List group item heading</h5>
                                            <small>3 days ago</small>
                                        </div>
                                        <p className="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
                                        <small>Donec id elit non mi porta.</small>
                                    </ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">List group item heading</h5>
                                            <small>3 days ago</small>
                                        </div>
                                        <p className="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
                                        <small>Donec id elit non mi porta.</small>
                                    </ListGroupItem>
                                    <ListGroupItem tag="a" href="#" className="list-group-item list-group-item-action flex-column align-items-start ">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">List group item heading</h5>
                                            <small>3 days ago</small>
                                        </div>
                                        <p className="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
                                        <small>Donec id elit non mi porta.</small></ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Listgroup));