import React, { Component } from 'react';
import { Row, Col, Card, CardBody} from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import BarChart from '../InitCharts/chartjs/barchart';
import LineChart from '../InitCharts/chartjs/linechart';
import DountChart from '../InitCharts/chartjs/dountchart';
import PieChart from '../InitCharts/chartjs/piechart';
import RadarChart from '../InitCharts/chartjs/radarchart';
import PolarChart from '../InitCharts/chartjs/polarchart';

class ChartjsChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Bar Chart</h4>
                                <div className="chart_container">
                                    <BarChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Line Chart</h4>
                                <div className="chart_container">
                                    <LineChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Doughnut Chart</h4>
                                <div className="chart_container">
                                    <DountChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Pie Chart</h4>
                                <div className="chart_container">
                                    <PieChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Polar Chart</h4>
                                <div className="chart_container">
                                    <PolarChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Radar Chart</h4>
                                <div className="chart_container">
                                    <RadarChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(ChartjsChart));