import React, { Component } from 'react';
import C3Chart from 'react-c3js';

class donut extends Component {
    render() {
        var donutData = {
            columns: [
                ['data1', 30],
                ['data2', 120],
                ['data3', 20],
                ['data4', 40],
                ['data5', 50],
                ['data6', 100],
            ],
            type: 'donut',
            onclick: function(d, i) { console.log("onclick", d, i); },
            onmouseover: function(d, i) { console.log("onmouseover", d, i); },
            onmouseout: function(d, i) { console.log("onmouseout", d, i); },

            colors: {
                data1: '#7B52AB',
                data2: '#553285',
                data3: '#36175E',
                data4: '#9768D1',
                data5: '#25064C',
                data6: '#7B52AB',
            }
        }
        var donutOption = {
            title: "Iris Petal Width"
        }

        return (
            <React.Fragment>
                <C3Chart data={donutData} donut={donutOption} />
            </React.Fragment>
        );
    }
}

export default donut;