import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Table, Label, Button, Input, ButtonDropdown,DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Inbox extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="mb-4">
                        <Card>
                            <CardBody>
                                <h3>
                                    Mailbox
                                    <small className="ml-3">13 new messages</small>
                                </h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="3" sm="12">
                        <Card>
                            <CardBody>
                                <Link to="/#" className="btn btn-primary btn-block">Compose</Link>
                                <ul className="list-unstyled mail_tabs">
                                    <li className="active">
                                        <Link to="/#">
                                            <i className="fa fa-inbox"></i> Inbox <span
                                                className="badge badge-primary pull-right">6</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-send-o"></i> Sent
                                    </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-edit"></i> Drafts <span
                                                className="badge badge-accent pull-right">2</span>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-star-o"></i> Important
                                    </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/#">
                                            <i className="fa fa-trash-o"></i> Trash
                                    </Link>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="9" sm="12" className="mt-mob-4">
                        <Card>
                            <CardBody>
                                <div className="mail_content">
                                    <Row>
                                        <div className="col-lg-12">
                                            <h3 className="mail_head">Inbox <span className="inbox_numb bg-primary">8</span></h3>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="pull-right all_mails_btn">
                                                <div className="btn-group btn-split mail_more_btn mt-0">
                                                    <ButtonDropdown isOpen={this.state.dropdLink} toggle={() => this.setState({ dropdLink: !this.state.dropdLink })} className="mb-3 mr-2 btn-split">
                                                        <Button id="caret" color="primary">Dropdown</Button>
                                                        <DropdownToggle caret color="primary" />
                                                        <DropdownMenu>
                                                            <DropdownItem>Dropdown</DropdownItem>
                                                            <DropdownItem>Another action</DropdownItem>
                                                            <DropdownItem>Something else here</DropdownItem>
                                                            <DropdownItem divider />
                                                            <DropdownItem>Separated link</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mail_list col-lg-12 table-responsive">
                                            <Table responsive className="table table-striped table-hover">
                                                <tbody>
                                                    <tr className="unread" id="mail_msg_1">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" defaultChecked className="custom-control-input"
                                                                    id="customCheck1" />
                                                                <Label className="custom-control-label" for="customCheck1"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star-o icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">John Smith</td>
                                                        <td className="open-view"><span
                                                            className="label label-primary mr-2">Test Label</span>&nbsp;<span
                                                                className="msgtext">Hello, hope you having a great day ahead.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">19:34</span></td>
                                                    </tr>
                                                    <tr id="mail_msg_17">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck121" />
                                                                <Label className="custom-control-label" for="customCheck121"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">Laura Willson</td>
                                                        <td className="open-view"><span className="msgtext">Your product seems amazingly smart and elegant to use.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">17 Feb</span></td>
                                                    </tr>
                                                    <tr id="mail_msg_18">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck13" />
                                                                <Label className="custom-control-label" for="customCheck13"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star-o icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">Jane D.</td>
                                                        <td className="open-view"><span className="msgtext"><span
                                                            className="label label-secondary mr-2">Successful</span>We play, dance and love. Share love all.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">17 Feb</span></td>
                                                    </tr>
                                                    <tr id="mail_msg_19">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck4" />
                                                                <Label className="custom-control-label" for="customCheck4"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star-o icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">John Smith</td>
                                                        <td className="open-view"><span
                                                            className="label label-danger mr-2">Congrats</span>&nbsp;<span
                                                                className="msgtext">Hello, hope you having a great day ahead.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">17 Feb</span></td>
                                                    </tr>
                                                    <tr id="mail_msg_20">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck5" />
                                                                <Label className="custom-control-label" for="customCheck5"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">Laura Willson</td>
                                                        <td className="open-view"><span className="msgtext">Your product seems amazingly smart and elegant to use.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">17 Feb</span></td>
                                                    </tr>
                                                    <tr className="unread">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck16" />
                                                                <Label className="custom-control-label" for="customCheck16"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star-o icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">Jane D.</td>
                                                        <td className="open-view"><span className="msgtext">We play, dance and love. Share love all around you.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">16 Feb</span></td>
                                                    </tr>
                                                    <tr id="mail_msg_22">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck17" />
                                                                <Label className="custom-control-label" for="customCheck17"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star-o icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">John Smith</td>
                                                        <td className="open-view"><span
                                                            className="label label-primary mr-2">Test Label</span>&nbsp;<span
                                                                className="msgtext">Hello, hope you having a great day ahead.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">16 Feb</span></td>
                                                    </tr>
                                                    <tr id="mail_msg_23">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck18" />
                                                                <Label className="custom-control-label" for="customCheck18"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">Laura Willson</td>
                                                        <td className="open-view"><span className="msgtext">Your product seems amazingly smart and elegant to use.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">16 Feb</span></td>
                                                    </tr>
                                                    <tr id="mail_msg_24">
                                                        <td className="">
                                                            <div className="custom-control custom-checkbox primary-checkbox">
                                                                <Input type="checkbox" className="custom-control-input"
                                                                    id="customCheck19" />
                                                                <Label className="custom-control-label" for="customCheck19"></Label>
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <div className="star"><i className="fa fa-star-o icon-accent"></i></div>
                                                        </td>
                                                        <td className="open-view">Jane D.</td>
                                                        <td className="open-view"><span className="msgtext">We play, dance and love. Share love all around you.</span>
                                                        </td>
                                                        <td className="open-view"><span className="msg_time">16 Feb</span></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <nav aria-label="Page navigation" className="mt-4">
                                                <ul className="pagination justify-content-center">
                                                    <li className="page-item disabled">
                                                        <Link to="/#" className="page-link" tabIndex="-1">
                                                            <span className="ti-angle-left"></span>
                                                        </Link>
                                                    </li>
                                                    <li className="page-item active"><Link to="/#" className="page-link">1</Link></li>
                                                    <li className="page-item"><Link to="/#" className="page-link">2</Link></li>
                                                    <li className="page-item"><Link to="/#" className="page-link">3</Link></li>
                                                    <li className="page-item">
                                                        <Link to="/#" className="page-link">
                                                            <span className="ti-angle-right"></span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Inbox));