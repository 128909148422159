import React, { Component } from 'react';
import C3Chart from 'react-c3js';

class combination extends Component {
    render() {
        var combinationData = {
            columns: [
                ['data1', 30, 20, 50, 40, 60, 50],
                ['data2', 200, 130, 90, 240, 130, 220],
                ['data3', 300, 200, 160, 400, 250, 250],
                ['data4', 200, 130, 90, 240, 130, 220],
                ['data5', 130, 120, 150, 140, 160, 150],
                ['data6', 90, 70, 20, 50, 60, 120],
            ],
            type: 'bar',
            types: {
                data3: 'spline',
                data4: 'line',
                data6: 'area',
            },
            groups: [
                ['data1', 'data2']
            ],

            colors: {
                data1: '#7B52AB',
                data2: '#9768D1',
                data3: '#9768D1',
                data4: '#7B52AB',
                data5: '#553285',
                data6: '#9768D1',


            }
        }
        var axisOptions = {
            y: {
                show: true
            },
            x: {
                show: true
            }
        }

        return (
            <React.Fragment>
                <C3Chart data={combinationData} axis={axisOptions} />
            </React.Fragment>
        );
    }
}

export default combination;