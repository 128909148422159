import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="12" className="stretched_card  mt-mob-4">
                        <Card>
                            <CardBody>
                                <div className="card_title">Bootstrap Grid System</div>
                                <Row>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>
                                    <Col xl="1" lg="2" md="3" sm="6">
                                        <div className="grid-col">col-1</div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg="2" md="4" sm="6">
                                        <div className="grid-col">col-2</div>
                                    </Col>
                                    <Col lg="2" md="4" sm="6">
                                        <div className="grid-col">col-2</div>
                                    </Col>
                                    <Col lg="2" md="4" sm="6">
                                        <div className="grid-col">col-2</div>
                                    </Col>
                                    <Col lg="2" md="4" sm="6">
                                        <div className="grid-col">col-2</div>
                                    </Col>
                                    <Col lg="2" md="4" sm="6">
                                        <div className="grid-col">col-2</div>
                                    </Col>
                                    <Col lg="2" md="4" sm="6">
                                        <div className="grid-col">col-2</div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg="3" md="6">
                                        <div className="grid-col">col-3</div>
                                    </Col>
                                    <Col lg="3" md="6">
                                        <div className="grid-col">col-3</div>
                                    </Col>
                                    <Col lg="3" md="6">
                                        <div className="grid-col">col-3</div>
                                    </Col>
                                    <Col lg="3" md="6">
                                        <div className="grid-col">col-3</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <div className="grid-col">col-4</div>
                                    </Col>
                                    <Col md="4">
                                        <div className="grid-col">col-4</div>
                                    </Col>
                                    <Col md="4">
                                        <div className="grid-col">col-4</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="grid-col">col-6</div>
                                    </Col>
                                    <Col md="6">
                                        <div className="grid-col">col-6</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="grid-col">col-12</div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Grid));