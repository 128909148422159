import React, { Component } from 'react';
class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="footer-area">
                        <p>&copy; Copyright {new Date().getFullYear()}. All right reserved. Template by Raventhemez.</p>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
export default Footer;






