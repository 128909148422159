import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Typography extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Headings</h4>
                                <h1>h1. Heading</h1>
                                <hr />
                                <h2>h2. Heading</h2>
                                <hr />
                                <h3>h3. Heading</h3>
                                <hr />
                                <h4>h4. Heading</h4>
                                <hr />
                                <h5>h5. Heading</h5>
                                <hr />
                                <h6>h6. Heading</h6>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Customizing Headings</h4>
                                <h1>
                                    Fancy display heading
                                <small className="text-muted d-block mt-2">With faded secondary text</small>
                                </h1>
                                <hr />
                                <h2>
                                    Fancy display heading
                                <small className="text-muted d-block mt-2">With faded secondary text</small>
                                </h2>
                                <hr />
                                <h3>
                                    Fancy display heading
                                <small className="text-muted d-block mt-2">With faded secondary text</small>
                                </h3>
                                <hr />
                                <h4>
                                    Fancy display heading
                                <small className="text-muted d-block mt-2">With faded secondary text</small>
                                </h4>
                                <hr />
                                <h5>
                                    Fancy display heading
                                <small className="text-muted d-block mt-2">With faded secondary text</small>
                                </h5>
                                <hr />
                                <h6>
                                    Fancy display heading
                                <small className="text-muted d-block mt-2">With faded secondary text</small>
                                </h6>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Blockquotes</h4>
                                <blockquote className="blockquote">
                                    <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius illo fuga sunt nobis possimus incidunt dolor ratione aspernatur doloremque amet, quisquam porro esse distinctio error. Delectus vitae obcaecati, suscipit velit vero illo maxime illum perferendis magnam tenetur praesentium non eligendi.</p>
                                    <footer className="blockquote-footer">Someone famous in
                                    <cite title="Source Title">Source Title
                                    </cite>
                                    </footer>
                                </blockquote>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Headings Class</h4>
                                <p className="h1">h1. Heading 1</p>
                                <hr />
                                <p className="h2">h2. Heading 2</p>
                                <hr />
                                <p className="h3">h3. Heading 3</p>
                                <hr />
                                <p className="h4">h4. Heading 4</p>
                                <hr />
                                <p className="h5">h5. Heading 5</p>
                                <hr />
                                <p className="h6">h6. Heading 6</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Inline Text Elements</h4>
                                <p>You can use the mark tag to
                                <mark>highlight</mark> text.</p>
                                <hr />
                                <p>
                                    <del>This line of text is meant to be treated as deleted text.</del>
                                </p>
                                <hr />
                                <p>
                                    <s>This line of text is meant to be treated as no longer accurate.</s>
                                </p>
                                <hr />
                                <p>
                                    <ins>This line of text is meant to be treated as an addition to the document.</ins>
                                </p>
                                <hr />
                                <p><u>This line of text will render as underlined</u></p>
                                <hr />
                                <p><small>This line of text is meant to be treated as fine print.</small></p>
                                <hr />
                                <p><strong>This line rendered as bold text.</strong></p>
                                <hr />
                                <p><em>This line rendered as italicized text.</em></p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Text Color</h4>
                                <p className="text-primary">You can use the mark tag to highlight text.</p>
                                <hr />
                                <p className="text-secondary">This line of text is meant to be treated as deleted text.</p>
                                <hr />
                                <p className="text-success">This line of text is meant to be treated as no longer accurate.</p>
                                <hr />
                                <p className="text-danger">This line of text is meant to be treated as an addition to the document.</p>
                                <hr />
                                <p className="text-warning">This line of text will render as underlined</p>
                                <hr />
                                <p className="text-info">This line of text is meant to be treated as fine print.</p>
                                <hr />
                                <p className="text-dark">This line rendered as italicized text.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Typography));