import React, { Component } from 'react';
import { Row, Col, Card, CardBody} from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import AreaChart from '../InitCharts/c3charts/areachart';
import Spline from '../InitCharts/c3charts/spline';
import Zoom from '../InitCharts/c3charts/zoom';
import Scatter from '../InitCharts/c3charts/scatter';
import Stacked from '../InitCharts/c3charts/stacked';
import Combination from '../InitCharts/c3charts/combination';
import Pie from '../InitCharts/c3charts/pie';
import Donut from '../InitCharts/c3charts/donut';
import '../../assets/default-assets/c3.css';

class ChartjsChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Area Chart</h4>
                                <AreaChart />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Spline Chart</h4>
                                <Spline />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Zoom Chart</h4>
                                <Zoom />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Scatter Chart</h4>
                                <Scatter />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Stacked Chart</h4>
                                <Stacked />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Combination Chart</h4>
                                <Combination />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Pie Chart</h4>
                                <Pie />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Donut Chart</h4>
                                <Donut />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(ChartjsChart));