import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class BarChart extends Component {

    render() {
        const data = {
            labels: ["January", "February", "March", "April", "May", "June", "July"],
            datasets: [
                {
                    label: 'Profit Months',
                    data: [12, 19, 3, 17, 28, 24, 7],
                    backgroundColor: "#7B52AB",
                    borderColor: "#7B52AB",
                    borderWidth: 2
                },
                {
                    label: 'Loss Month',
                    data: [30, 29, 5, 5, 20, 3, 10],
                    backgroundColor: "#553285",
                    borderColor: "#553285",
                    borderWidth: 2
                }
            ]
        };

        const option = {
            maintainAspectRatio: false,
            legend: {
                display: false,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }]
            },
        }

        return (
            <React.Fragment>
                <Bar width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default BarChart;   