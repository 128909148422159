import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Progress } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Progressbr extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Progress</h4>
                                <div className="progress_area">
                                    <div className="mb-4">
                                        <Progress color="primary" value={0} ></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="primary" value={25}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="primary" value={50}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="primary" value={75}></Progress>
                                    </div>
                                    <div>
                                        <Progress color="primary" value={100}></Progress>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Progress With Labels</h4>
                                <div className="progress_area">
                                    <div className="mb-4">
                                        <Progress color="primary" value={25}>25%</Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="primary" value={35}>35%</Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="primary" value={50}>50%</Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="primary" value={65}>65%</Progress>
                                    </div>
                                    <div>
                                        <Progress color="primary" value={80}>80%</Progress>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Progress With Backgrounds</h4>
                                <div className="progress_area">
                                    <div className="mb-4">
                                        <Progress color="success" value={25}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="danger" value={50}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress color="warning" value={75}></Progress>
                                    </div>
                                    <div>
                                        <Progress color="dark" value={100}></Progress>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Progress With Multiple Bars</h4>
                                <div className="progress_area">
                                    <div className="mb-4">
                                        <Progress multi>
                                            <Progress bar color='primary' value={15}></Progress>
                                            <Progress bar color='success' value={30}></Progress>
                                            <Progress bar color='info' value={20}></Progress>
                                        </Progress>
                                    </div>

                                    <div className="mb-4">
                                        <Progress multi>
                                            <Progress bar color='primary' value={15}></Progress>
                                            <Progress bar color='warning' value={30}></Progress>
                                            <Progress bar color='dark' value={20}></Progress>
                                            <Progress bar color='success' value={20}></Progress>
                                        </Progress>
                                    </div>

                                    <div className="mb-4">
                                        <Progress multi>
                                            <Progress bar color='primary' value={15}></Progress>
                                            <Progress bar color='success' value={30}></Progress>
                                            <Progress bar color='info' value={20}></Progress>
                                            <Progress bar color='danger' value={20}></Progress>
                                        </Progress>
                                    </div>

                                    <div className="mb-4">
                                        <Progress multi>
                                            <Progress bar color='secondary' value={15}></Progress>
                                            <Progress bar color='info' value={30}></Progress>
                                            <Progress bar color='warning' value={20}></Progress>
                                            <Progress bar color='success' value={20}></Progress>
                                        </Progress>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Progress With Striped</h4>
                                <div className="progress_area">
                                    <div className="mb-4">
                                        <Progress striped color="success" value={25}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress striped color="danger" value={50}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress striped color="warning" value={75}></Progress>
                                    </div>
                                    <div>
                                        <Progress striped color="dark" value={100}></Progress>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Progress With Animated Striped</h4>
                                <div className="progress_area">
                                    <div className="mb-4">
                                        <Progress striped animated color="success" value={25}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress striped animated color="danger" value={50}></Progress>
                                    </div>
                                    <div className="mb-4">
                                        <Progress striped animated color="warning" value={75}></Progress>
                                    </div>
                                    <div>
                                        <Progress striped animated color="dark" value={100}></Progress>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Progressbr));