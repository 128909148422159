import { combineReducers  } from 'redux';
import Layout from './layout/reducer';

//============ Auth Reducers =============*
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';


const rootReducer = combineReducers({ Layout, Account, Login, Forget });

export default rootReducer;