import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class EtLine extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card_title">ET Line ICONS</h4>
                                <div className="et-examples">
                                    <span className="box1">
                                        <span aria-hidden="true" className="icon-mobile"></span>&nbsp;icon-mobile
                                </span>
                                    <span className="box1">
                                        <span aria-hidden="true" className="icon-laptop"></span>&nbsp;icon-laptop
                                </span>
                                    <span className="box1"><span aria-hidden="true" className="icon-desktop"></span>&nbsp;icon-desktop</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-tablet"></span>&nbsp;icon-tablet</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-phone"></span>&nbsp;icon-phone</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-document"></span>&nbsp;icon-document</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-documents"></span>&nbsp;icon-documents</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-search"></span>&nbsp;icon-search</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-clipboard"></span>&nbsp;icon-clipboard</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-newspaper"></span>&nbsp;icon-newspaper</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-notebook"></span>&nbsp;icon-notebook</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-book-open"></span>&nbsp;icon-book-open</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-browser"></span>&nbsp;icon-browser</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-calendar"></span>&nbsp;icon-calendar</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-presentation"></span>&nbsp;icon-presentation</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-picture"></span>&nbsp;icon-picture</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-pictures"></span>&nbsp;icon-pictures</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-video"></span>&nbsp;icon-video</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-camera"></span>&nbsp;icon-camera</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-printer"></span>&nbsp;icon-printer</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-toolbox"></span>&nbsp;icon-toolbox</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-briefcase"></span>&nbsp;icon-briefcase</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-wallet"></span>&nbsp;icon-wallet</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-gift"></span>&nbsp;icon-gift</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-bargraph"></span>&nbsp;icon-bargraph</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-grid"></span>&nbsp;icon-grid</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-expand"></span>&nbsp;icon-expand</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-focus"></span>&nbsp;icon-focus</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-edit"></span>&nbsp;icon-edit</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-adjustments"></span>&nbsp;icon-adjustments</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-ribbon"></span>&nbsp;icon-ribbon</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-hourglass"></span>&nbsp;icon-hourglass</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-lock"></span>&nbsp;icon-lock</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-megaphone"></span>&nbsp;icon-megaphone</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-shield"></span>&nbsp;icon-shield</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-trophy"></span>&nbsp;icon-trophy</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-flag"></span>&nbsp;icon-flag</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-map"></span>&nbsp;icon-map</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-puzzle"></span>&nbsp;icon-puzzle</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-basket"></span>&nbsp;icon-basket</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-envelope"></span>&nbsp;icon-envelope</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-streetsign"></span>&nbsp;icon-streetsign</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-telescope"></span>&nbsp;icon-telescope</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-gears"></span>&nbsp;icon-gears</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-key"></span>&nbsp;icon-key</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-paperclip"></span>&nbsp;icon-paperclip</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-attachment"></span>&nbsp;icon-attachment</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-pricetags"></span>&nbsp;icon-pricetags</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-lightbulb"></span>&nbsp;icon-lightbulb</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-layers"></span>&nbsp;icon-layers</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-pencil"></span>&nbsp;icon-pencil</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-tools"></span>&nbsp;icon-tools</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-tools-2"></span>&nbsp;icon-tools-2</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-scissors"></span>&nbsp;icon-scissors</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-paintbrush"></span>&nbsp;icon-paintbrush</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-magnifying-glass"></span>&nbsp;icon-magnifying-glass</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-circle-compass"></span>&nbsp;icon-circle-compass</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-linegraph"></span>&nbsp;icon-linegraph</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-mic"></span>&nbsp;icon-mic</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-strategy"></span>&nbsp;icon-strategy</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-beaker"></span>&nbsp;icon-beaker</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-caution"></span>&nbsp;icon-caution</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-recycle"></span>&nbsp;icon-recycle</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-anchor"></span>&nbsp;icon-anchor</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-profile-male"></span>&nbsp;icon-profile-male</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-profile-female"></span>&nbsp;icon-profile-female</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-bike"></span>&nbsp;icon-bike</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-wine"></span>&nbsp;icon-wine</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-hotairballoon"></span>&nbsp;icon-hotairballoon</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-globe"></span>&nbsp;icon-globe</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-genius"></span>&nbsp;icon-genius</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-map-pin"></span>&nbsp;icon-map-pin</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-dial"></span>&nbsp;icon-dial</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-chat"></span>&nbsp;icon-chat</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-heart"></span>&nbsp;icon-heart</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-cloud"></span>&nbsp;icon-cloud</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-upload"></span>&nbsp;icon-upload</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-download"></span>&nbsp;icon-download</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-target"></span>&nbsp;icon-target</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-hazardous"></span>&nbsp;icon-hazardous</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-piechart"></span>&nbsp;icon-piechart</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-speedometer"></span>&nbsp;icon-speedometer</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-global"></span>&nbsp;icon-global</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-compass"></span>&nbsp;icon-compass</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-lifesaver"></span>&nbsp;icon-lifesaver</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-clock"></span>&nbsp;icon-clock</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-aperture"></span>&nbsp;icon-aperture</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-quote"></span>&nbsp;icon-quote</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-scope"></span>&nbsp;icon-scope</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-alarmclock"></span>&nbsp;icon-alarmclock</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-refresh"></span>&nbsp;icon-refresh</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-happy"></span>&nbsp;icon-happy</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-sad"></span>&nbsp;icon-sad</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-facebook"></span>&nbsp;icon-facebook</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-twitter"></span>&nbsp;icon-twitter</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-googleplus"></span>&nbsp;icon-googleplus</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-rss"></span>&nbsp;icon-rss</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-tumblr"></span>&nbsp;icon-tumblr</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-linkedin"></span>&nbsp;icon-linkedin</span>
                                    <span className="box1"><span aria-hidden="true" className="icon-dribbble"></span>&nbsp;icon-dribbble</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(EtLine));