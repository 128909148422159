import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

class ForgetPassword extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="lock-screen forget_bg">
                    <div className="login-form credentials-form">
                        <AvForm className="form-horizontal m-t-30" onValidSubmit={this.handleSubmit} >
                            <div className="lock-form-head">
                            <h4>Screenlock</h4>
                <p>Hey! Unlock Your Screen.</p>
                            </div>
                            <div className="login-form-body text-center">
                                <div className="mb-0 text-left">
                                    <AvField name="email" label="Enter Email" value='' placeholder="Enter Email" type="email" required />
                                    <div className="submit-btn-area">
                                        <Button color="primary" className="btn btn-primary" type="submit">Submit <i className="ti-arrow-right"></i></Button>
                                    </div>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(ForgetPassword);

