import React, { Component } from 'react';
import { Row, Col, Table, Card, CardBody, Progress } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Vector from './Vectormap/Vectormap';
import BarChart from './HomeCharts/barchart';
import RecentSaleChart from './HomeCharts/recentSaleChart';
import image1 from "../../assets/images/team/member1.jpg";
import image2 from "../../assets/images/team/member2.jpg";
import image3 from "../../assets/images/team/member3.jpg";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>
                <Col lg="12" className="rt_subheader">
                    <div className="rt_subheader_main">
                        <h3 className="rt_subheader_title mb-mob-2">Hello David Jhon!</h3>
                        <div className="rt_breadcrumb mb-mob-2">
                            <Link to="/" className="rt_breadcrumb_home_icon"><i className="feather ft-home"></i></Link>
                            <span className="rt_breadcrumb_separator"><i className="feather ft-chevrons-right"></i></span>
                            <Link to="/" className="breadcrumb_link">Home</Link>
                            <span className="rt_breadcrumb_separator"><i className="feather ft-chevrons-right"></i></span>
                            <Link to="/" className="breadcrumb_link">Main Dashboard</Link>
                        </div>
                    </div>
                    <div className="subheader_btns">
                        <Link to="/" className="btn btn-sm btn-primary btn-inverse-primary mr-2"><i className="feather ft-edit mr-0"></i></Link>
                        <Link to="/" className="btn btn-sm btn-primary btn-inverse-primary mr-2"><i className="feather ft-watch mr-0"></i></Link>
                        <Link to="/" className="btn btn-sm btn-primary btn-inverse-primary">
                            <span>Today:</span>&nbsp;
                            <span>Jan 21</span>
                            <i className="feather ft-calendar ml-2"></i>
                        </Link>
                    </div>
                </Col>
                <Row className="mt-4">
                    <div className="col-lg-3 col-md-6 stretched_card pl-mob-3 mb-mob-4">
                        <Card className="bg-primary analytics_card">
                            <CardBody>
                                <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                    <div className="icon-rounded">
                                        <i className="feather ft-trending-up text-primary"></i>
                                    </div>
                                    <div className="text-white">
                                        <p className="mt-xl-0 text-xl-left mb-2">Total Traffic</p>
                                        <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                            <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1 text-white">600</h3>
                                            <small className="stats_icon">3% <span className="feather ft-chevron-up"></span></small>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-md-6 stretched_card mb-mob-4">
                        <Card className="bg-success analytics_card">
                            <CardBody>
                                <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                    <div className="icon-rounded">
                                        <i className="feather ft-shopping-bag text-success"></i>
                                    </div>
                                    <div className="text-white">
                                        <p className="mt-xl-0 text-xl-left mb-2">Total Orders</p>
                                        <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                            <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1 text-white">2300</h3>
                                            <small className="stats_icon">5% <span className="feather ft-chevron-up"></span></small>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-md-6 stretched_card mb-mob-4">
                        <Card className="bg-danger analytics_card">
                            <CardBody>
                                <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                    <div className="icon-rounded">
                                        <i className="feather ft-users text-danger"></i>
                                    </div>
                                    <div className="text-white">
                                        <p className="mt-xl-0 text-xl-left mb-2">New Customers</p>
                                        <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                            <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1 text-white">2765</h3>
                                            <small className="stats_icon">2% <span className="feather ft-chevron-up"></span></small>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-md-6 stretched_card pr-mob-3">
                        <Card className="bg-dark analytics_card">
                            <CardBody>
                                <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                    <div className="icon-rounded">
                                        <i className="feather ft-box text-dark"></i>
                                    </div>
                                    <div className="text-white">
                                        <p className="mt-xl-0 text-xl-left mb-2">Total Products</p>
                                        <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                            <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1 text-white">628</h3>
                                            <small className="stats_icon">7% <span className="feather ft-chevron-up"></span></small>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="col-lg-8 stretched_card mt-4">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card_title">Sales By Profit</h4>
                                <div className="d-flex mt-2 total_sales_sec mt-3">
                                    <div className="col-md-5">
                                        <p className="h1 lh-10 m-0 fw-300"><span>43,759</span>
                                        </p><small className="small text-success fs-11">Total Sales</small>
                                    </div>
                                    <div className="col-md-7 row no-gutters mt-2">
                                        <div className="col-sm-6">
                                            <div className="sales-info"><span className="sales-info-box bg-success mr-2"></span>
                                                <div className="sales-info-body">
                                                    <h6>Total Orders</h6><small><span className="text-success"><i className="feather ft-chevron-up"></i> 17%</span> this month</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="sales-info"><span className="sales-info-box bg-primary mr-2"></span>
                                                <div className="sales-info-body">
                                                    <h6>Total Products</h6><small><span className="text-danger"><i className="feather ft-chevron-down"></i> 23%</span> this month</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="chart_container mt-3">
                                    <BarChart />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 stretched_card mt-4">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card_title">Vist by USA</h4>
                                <Vector value="us_aea" width="400" color="#999999" />
                                <div className="country_vists">
                                    <div className="country_vists_item">
                                        <div className="country_vists_wrapper">
                                            <div className="country_vists_title">
                                                New Mexico
                                            </div>
                                            <div className="country_vists_value">
                                                65%
                                            </div>
                                        </div>
                                        <div className="country_vists_progress">
                                            <Progress color="primary" value={75}></Progress>
                                        </div>
                                    </div>
                                    <div className="country_vists_item">
                                        <div className="country_vists_wrapper">
                                            <div className="country_vists_title">
                                                Texas
                                            </div>
                                            <div className="country_vists_value">
                                                40%
                                            </div>
                                        </div>
                                        <div className="country_vists_progress">
                                            <Progress color="primary" value={40}></Progress>
                                        </div>
                                    </div>
                                    <div className="country_vists_item">
                                        <div className="country_vists_wrapper">
                                            <div className="country_vists_title">
                                                Utah
                                            </div>
                                            <div className="country_vists_value">
                                                60%
                                            </div>
                                        </div>
                                        <div className="country_vists_progress">
                                            <Progress color="primary" value={60}></Progress>
                                        </div>
                                    </div>
                                    <div className="country_vists_item">
                                        <div className="country_vists_wrapper">
                                            <div className="country_vists_title">
                                                California
                                            </div>
                                            <div className="country_vists_value">
                                                75%
                                            </div>
                                        </div>
                                        <div className="country_vists_progress">
                                            <Progress color="primary" value={75}></Progress>
                                        </div>
                                    </div>
                                    <div className="country_vists_item">
                                        <div className="country_vists_wrapper">
                                            <div className="country_vists_title">
                                                Florida
                                            </div>
                                            <div className="country_vists_value">
                                                50%
                                            </div>
                                        </div>
                                        <div className="country_vists_progress">
                                            <Progress color="primary" value={50}></Progress>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Col lg="4" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title mb-3">Recent Activities</h4>
                                <div className="table-responsive">
                                    <Table className="table table-centered table-hover mb-0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="media recent_activity mt-2">
                                                        <img className="mr-3 rounded-circle" src={image1} width="50" alt="Hello" />
                                                        <div className="media-body">
                                                            <h6 className="mt-0 mb-1">Jhon Doe
                                                        <small className="font-weight-normal d-block mt-1">18 Jan 2019 11:28
                                                                pm
                                                        </small>
                                                            </h6>
                                                            <span className="mt-2 d-block">Many desktop publishing</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="media recent_activity mt-2">
                                                        <img className="mr-3 rounded-circle" src={image2} width="50" alt="ImageText" />
                                                        <div className="media-body">
                                                            <h6 className="mt-0 mb-1">David Ron
                                                        <small className="font-weight-normal d-block mt-1">18 Jan 2019 11:28
                                                                pm
                                                        </small>
                                                            </h6>
                                                            <span className="mt-2 d-block">Many desktop publishing</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="media recent_activity mt-2">
                                                        <img className="mr-3 rounded-circle" src={image3} width="50" alt="TextALt" />
                                                        <div className="media-body">
                                                            <h6 className="mt-0 mb-1">Mike Hussey
                                                        <small className="font-weight-normal d-block mt-1">18 Jan 2019 11:28
                                                                pm
                                                        </small>
                                                            </h6>
                                                            <span className="mt-2 d-block">Many desktop publishing</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="4" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <div className="card_title">AVERAGE SALE SIZE</div>
                                <div className="avg_sale_card">
                                    <span className="badge badge-pill mb-2 badge-danger"><span className="feather ft-chevron-down mr-2"></span>23.36%</span>
                                    <h1 className="mt-2">$956.78</h1>
                                    <h4 className="mb-2">PER SALE</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque debitis dolor.</p>
                                </div>
                                <RecentSaleChart />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" className="mt-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Traffic By Browsers</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid assumenda aut.</p>
                                <div className="traffic_progress">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 title">Google Chrome</span>
                                        </div>
                                        <div className="d-flex flex-column text-right">
                                            <span className="d-flex mr-1">
                                                <span className="badge badge-pill mb-2 badge-success">80% <i className="feather ft-arrow-up text-white"></i></span>

                                            </span>
                                        </div>
                                    </div>
                                    <Progress color="success" value={80}></Progress>
                                </div>
                                <div className="traffic_progress mt-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 title">Firefox</span>
                                        </div>
                                        <div className="d-flex flex-column text-right">
                                            <span className="d-flex mr-1">
                                                <span className="badge badge-pill mb-2 badge-danger">60% <i className="feather ft-arrow-down text-white"></i></span>

                                            </span>
                                        </div>
                                    </div>
                                    <Progress color="danger" value={60}></Progress>
                                </div>
                                <div className="traffic_progress mt-4 mb-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 title">Microsoft Edge</span>
                                        </div>
                                        <div className="d-flex flex-column text-right">
                                            <span className="d-flex mr-1">
                                                <span className="badge badge-pill mb-2 badge-success">70% <i className="feather ft-arrow-up text-white"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                    <Progress color="success" value={70}></Progress>
                                </div>
                                <div className="traffic_progress mt-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 title">Safari</span>
                                        </div>
                                        <div className="d-flex flex-column text-right">
                                            <span className="d-flex mr-1">
                                                <span className="badge badge-pill mb-2 badge-danger">30% <i className="feather ft-arrow-down text-white"></i></span>

                                            </span>
                                        </div>
                                    </div>
                                    <Progress color="danger" value={30}></Progress>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Orders Overview</h4>
                                <div className="single-table">
                                    <div className="table-responsive">
                                        <Table className="table table-hover progress-table text-center">
                                            <thead className="text-uppercase">
                                                <tr>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Customer</th>
                                                    <th scope="col">Product</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">status</th>
                                                    <th scope="col">action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">6583</th>
                                                    <td>Mark Spence</td>
                                                    <td>Macbook Pro</td>
                                                    <td>09 / 07 / 2018</td>
                                                    <td>672.56$</td>
                                                    <td><span className="badge badge-primary">Progress</span></td>
                                                    <td>
                                                        <ul className="d-flex justify-content-center">
                                                            <li className="mr-3"><button type="button" className="btn btn-inverse-primary"><i className="fa fa-edit"></i></button></li>
                                                            <li><button type="button" className="btn btn-inverse-danger"><i className="ti-trash"></i></button></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">4652</th>
                                                    <td>David Rebon</td>
                                                    <td>iPhone X</td>
                                                    <td>09 / 07 / 2018</td>
                                                    <td>672.56$</td>
                                                    <td><span className="badge badge-warning">Pending</span></td>
                                                    <td>
                                                        <ul className="d-flex justify-content-center">
                                                            <li className="mr-3"><button type="button" className="btn btn-inverse-primary"><i className="fa fa-edit"></i></button></li>
                                                            <li><button type="button" className="btn btn-inverse-danger"><i className="ti-trash"></i></button></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">7292</th>
                                                    <td>Jhon Doe</td>
                                                    <td>Samsung</td>
                                                    <td>09 / 07 / 2018</td>
                                                    <td>672.56$</td>
                                                    <td><span className="badge badge-success">Completed</span></td>
                                                    <td>
                                                        <ul className="d-flex justify-content-center">
                                                            <li className="mr-3"><button type="button" className="btn btn-inverse-primary"><i className="fa fa-edit"></i></button></li>
                                                            <li><button type="button" className="btn btn-inverse-danger"><i className="ti-trash"></i></button></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">7826</th>
                                                    <td>Jessica Roy</td>
                                                    <td>Exercise Machine</td>
                                                    <td>09 / 07 / 2018</td>
                                                    <td>672.56$</td>
                                                    <td><span className="badge badge-danger">Stopped</span></td>
                                                    <td>
                                                        <ul className="d-flex justify-content-center">
                                                            <li className="mr-3"><button type="button" className="btn btn-inverse-primary"><i className="fa fa-edit"></i></button></li>
                                                            <li><button type="button" className="btn btn-inverse-danger"><i className="ti-trash"></i></button></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">2638</th>
                                                    <td>Malika Jhon</td>
                                                    <td>Machine</td>
                                                    <td>09 / 07 / 2018</td>
                                                    <td>483.56$</td>
                                                    <td><span className="badge badge-primary">Progress</span></td>
                                                    <td>
                                                        <ul className="d-flex justify-content-center">
                                                            <li className="mr-3"><button type="button" className="btn btn-inverse-primary"><i className="fa fa-edit"></i></button></li>
                                                            <li><button type="button" className="btn btn-inverse-danger"><i className="ti-trash"></i></button></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">9374</th>
                                                    <td>David Jess</td>
                                                    <td>Laptop</td>
                                                    <td>09 / 07 / 2018</td>
                                                    <td>473.56$</td>
                                                    <td><span className="badge badge-success">Completed</span></td>
                                                    <td>
                                                        <ul className="d-flex justify-content-center">
                                                            <li className="mr-3"><button type="button" className="btn btn-inverse-primary"><i className="fa fa-edit"></i></button></li>
                                                            <li><button type="button" className="btn btn-inverse-danger"><i className="ti-trash"></i></button></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Dashboard));