import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { AvForm } from 'availity-reactstrap-validation';

class ResetPassword extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="lock-screen forget_bg">
                    <div className="login-form credentials-form">
                        <AvForm className="form-horizontal m-t-30" onValidSubmit={this.handleSubmit} >
                            <div className="lock-form-head">
                                <h4>Password Reset Successfully !</h4>
                            </div>
                            <div className="login-form-body text-center">
                                <Alert color="success"> Your password reset successfully. Now You can <Link to="/login">Login</Link></Alert>
                            </div>
                        </AvForm>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(ResetPassword);

