import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import image1 from "../../assets/images/portfolio/bw1.jpg";
import image2 from "../../assets/images/portfolio/bw3.jpg";
import image3 from "../../assets/images/portfolio/bw4.jpg";
import image4 from "../../assets/images/portfolio/bw5.jpg";
import image5 from "../../assets/images/portfolio/bw7.jpg";
import image6 from "../../assets/images/portfolio/bw8.jpg";
import image7 from "../../assets/images/portfolio/bw9.jpg";
import image8 from "../../assets/images/portfolio/bw10.jpg";
import image9 from "../../assets/images/portfolio/bw11.jpg";
const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9];

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            fitToScreen: false,
            currentImage: 0
        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>
                {this.state.fitToScreen ?
                    <Lightbox mainSrc={images[this.state.currentImage]}
                        enableZoom={false}
                        imageCaption={"Image Caption"}
                        onCloseRequest={() => this.setState({ fitToScreen: false })}
                    /> : null}
                <Row>
                    <Col lg="12" className="stretched_card">
                        <Card>
                            <CardBody>

                                <div id="mt_portfolio" className="gallery-section gallery-page">
                                    <div className="container">
                                        <h4 className="card_title">Gallery</h4>
                                        <div className="row portfolio_row grid-masonry">
                                            <div className="isotopeContainer">
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image1} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 0 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 0 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image2} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 1 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 1 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image3} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 2 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 2 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image4} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 3 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 3 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image5} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 4 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 4 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image6} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 5 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 5 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image7} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 6 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 6 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image8} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 7 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 7 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="portfolio_grid grid no-padding isotopeSelector webdesign grid-item">
                                                    <figure className="effect-chico">
                                                        <img src={image9} alt="Portfolio" />
                                                        <figcaption>
                                                            <h3>
                                                                <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 8 })}>Portfolio Item</Link>
                                                                <span>

                                                                    <Link to="#" className="view_proj" onClick={() => this.setState({ fitToScreen: true, currentImage : 8 })}>
                                                                        <i className="ion-ios-plus-empty"></i>
                                                                    </Link>
                                                                </span>
                                                            </h3>

                                                        </figcaption>
                                                    </figure>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Gallery));