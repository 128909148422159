import React, { Component } from 'react';
import C3Chart from 'react-c3js';

class spline extends Component {
    render() {
        var splineData = {
            columns: [
                ['data1', 30, 200, 100, 400, 150, 250],
                ['data2', 130, 100, 140, 200, 150, 50]
            ],
            type: 'spline',
            colors: {
                data1: '#7B52AB',
                data2: '#9768D1',

            }
        }
        var axisOptions = {
            y: {
                show: true
            },
            x: {
                show: true
            }
        }

        return (
            <React.Fragment>
                <C3Chart data={splineData} axis={axisOptions} />
            </React.Fragment>
        );
    }
}

export default spline;