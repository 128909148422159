import React, { Component } from 'react';
import C3Chart from 'react-c3js';

class pie extends Component {
    render() {
        var pieData = {
            columns: [
                ['data1', 30],
                ['data2', 50]
            ],
            type: 'pie',

            colors: {
                data1: '#7B52AB',
                data2: '#553285'
            }
        }
        var pieOption = {
            enabled: true
        }

        return (
            <React.Fragment>
                <C3Chart data={pieData} pie={pieOption} />
            </React.Fragment>
        );
    }
}

export default pie;