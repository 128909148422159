import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class LineChart extends Component {

    render() {
        const data = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            datasets: [{
                label: 'Main Data',
                data: [12, 19, 3, 17, 6, 3, 7],
                backgroundColor: "rgba(151, 104, 209,0.5)",
                borderColor: "rgba(151, 104, 209,0.7)",
                borderWidth: 2
            }, {
                label: 'Basic Data',
                data: [2, 29, 5, 5, 2, 3, 10],
                backgroundColor: "rgba(123, 82, 171,0.5)",
                borderColor: "rgba(123, 82, 171,0.7)",
                borderWidth: 2
            }]
        };

        const option = {
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                }
            },

            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }]
            }
        }

        return (
            <React.Fragment>
                <Line width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default LineChart;   