import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, ButtonDropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Single Button Dropdowns</h4>
                                <UncontrolledDropdown >
                                    <DropdownToggle color='primary' caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='secondary' caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='success' caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='danger' caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='light' caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='dark' caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Outline Dropdowns</h4>
                                <UncontrolledDropdown >
                                    <DropdownToggle color='primary' outline caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='secondary' outline caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='success' outline caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='danger' outline caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='light' outline caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='dark' outline caret className="float-left mr-4 mb-3">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">SPLIT BUTTON</h4>
                                <ButtonDropdown isOpen={this.state.dropdLink} toggle={() => this.setState({ dropdLink: !this.state.dropdLink })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="primary">Dropdown</Button>
                                    <DropdownToggle caret color="primary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink1} toggle={() => this.setState({ dropdLink1: !this.state.dropdLink1 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="secondary">Dropdown</Button>
                                    <DropdownToggle caret color="secondary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink2} toggle={() => this.setState({ dropdLink2: !this.state.dropdLink2 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="success">Dropdown</Button>
                                    <DropdownToggle caret color="success" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink3} toggle={() => this.setState({ dropdLink3: !this.state.dropdLink3 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="danger">Dropdown</Button>
                                    <DropdownToggle caret color="danger" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink4} toggle={() => this.setState({ dropdLink4: !this.state.dropdLink4 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="warning">Dropdown</Button>
                                    <DropdownToggle caret color="warning" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink5} toggle={() => this.setState({ dropdLink5: !this.state.dropdLink5 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="info">Dropdown</Button>
                                    <DropdownToggle caret color="info" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink6} toggle={() => this.setState({ dropdLink6: !this.state.dropdLink6 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="light">Dropdown</Button>
                                    <DropdownToggle caret color="light" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink7} toggle={() => this.setState({ dropdLink7: !this.state.dropdLink7 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="dark">Dropdown</Button>
                                    <DropdownToggle caret color="dark" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Button Dropdowns Up</h4>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLink8} toggle={() => this.setState({ dropdLink8: !this.state.dropdLink8 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="primary">Dropdown</Button>
                                    <DropdownToggle caret color="primary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLink9} toggle={() => this.setState({ dropdLink9: !this.state.dropdLink9 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="secondary">Dropdown</Button>
                                    <DropdownToggle caret color="secondary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLink10} toggle={() => this.setState({ dropdLink10: !this.state.dropdLink10 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="success">Dropdown</Button>
                                    <DropdownToggle caret color="success" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLin11} toggle={() => this.setState({ dropdLin11: !this.state.dropdLin11 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="danger">Dropdown</Button>
                                    <DropdownToggle caret color="danger" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLink12} toggle={() => this.setState({ dropdLink12: !this.state.dropdLink12 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="warning">Dropdown</Button>
                                    <DropdownToggle caret color="warning" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLink13} toggle={() => this.setState({ dropdLink13: !this.state.dropdLink13 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="info">Dropdown</Button>
                                    <DropdownToggle caret color="info" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLink14} toggle={() => this.setState({ dropdLink14: !this.state.dropdLink14 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="light">Dropdown</Button>
                                    <DropdownToggle caret color="light" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="up" isOpen={this.state.dropdLink15} toggle={() => this.setState({ dropdLink15: !this.state.dropdLink15 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="dark">Dropdown</Button>
                                    <DropdownToggle caret color="dark" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Dropdowns Flat</h4>
                                <UncontrolledDropdown >
                                    <DropdownToggle color='primary' caret className="float-left mr-5 mb-3 btn-flat">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='secondary' caret className="float-left mr-5 mb-3 btn-flat">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='success' caret className="float-left mr-5 mb-3 btn-flat">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='danger' caret className="float-left mr-5 mb-3 btn-flat">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='light' caret className="float-left mr-5 mb-3 btn-flat">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='dark' caret className="float-left mr-5 mb-3 btn-flat">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">SPLIT BUTTON DROPDOWNS FLAT</h4>
                                <ButtonDropdown isOpen={this.state.dropdLink16} toggle={() => this.setState({ dropdLink16: !this.state.dropdLink16 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="primary" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="primary" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink17} toggle={() => this.setState({ dropdLink17: !this.state.dropdLink17 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="secondary" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="secondary" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink18} toggle={() => this.setState({ dropdLink18: !this.state.dropdLink18 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="success" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="success" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink19} toggle={() => this.setState({ dropdLink19: !this.state.dropdLink19 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="danger" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="danger" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink20} toggle={() => this.setState({ dropdLink20: !this.state.dropdLink20 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="warning" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="warning" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink21} toggle={() => this.setState({ dropdLink21: !this.state.dropdLink21 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="info" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="info" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink22} toggle={() => this.setState({ dropdLink22: !this.state.dropdLink22 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="light" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="light" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink23} toggle={() => this.setState({ dropdLink23: !this.state.dropdLink23 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="dark" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="dark" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Button Dropdowns Right</h4>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLink24} toggle={() => this.setState({ dropdLink24: !this.state.dropdLink24 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="primary">Dropdown</Button>
                                    <DropdownToggle caret color="primary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLink25} toggle={() => this.setState({ dropdLink25: !this.state.dropdLink25 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="secondary">Dropdown</Button>
                                    <DropdownToggle caret color="secondary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLink26} toggle={() => this.setState({ dropdLink26: !this.state.dropdLink26 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="success">Dropdown</Button>
                                    <DropdownToggle caret color="success" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLin27} toggle={() => this.setState({ dropdLin27: !this.state.dropdLin27 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="danger">Dropdown</Button>
                                    <DropdownToggle caret color="danger" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLink28} toggle={() => this.setState({ dropdLink28: !this.state.dropdLink28 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="warning">Dropdown</Button>
                                    <DropdownToggle caret color="warning" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLink29} toggle={() => this.setState({ dropdLink29: !this.state.dropdLink29 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="info">Dropdown</Button>
                                    <DropdownToggle caret color="info" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLink30} toggle={() => this.setState({ dropdLink30: !this.state.dropdLink30 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="light">Dropdown</Button>
                                    <DropdownToggle caret color="light" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="right" isOpen={this.state.dropdLink31} toggle={() => this.setState({ dropdLink31: !this.state.dropdLink31 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="dark">Dropdown</Button>
                                    <DropdownToggle caret color="dark" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Button Dropdowns Left</h4>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLink32} toggle={() => this.setState({ dropdLink32: !this.state.dropdLink32 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="primary">Dropdown</Button>
                                    <DropdownToggle caret color="primary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLink33} toggle={() => this.setState({ dropdLink33: !this.state.dropdLink33 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="secondary">Dropdown</Button>
                                    <DropdownToggle caret color="secondary" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLink34} toggle={() => this.setState({ dropdLink34: !this.state.dropdLink34 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="success">Dropdown</Button>
                                    <DropdownToggle caret color="success" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLin35} toggle={() => this.setState({ dropdLin35: !this.state.dropdLin35 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="danger">Dropdown</Button>
                                    <DropdownToggle caret color="danger" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLink36} toggle={() => this.setState({ dropdLink36: !this.state.dropdLink36 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="warning">Dropdown</Button>
                                    <DropdownToggle caret color="warning" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLink37} toggle={() => this.setState({ dropdLink37: !this.state.dropdLink37 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="info">Dropdown</Button>
                                    <DropdownToggle caret color="info" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLink38} toggle={() => this.setState({ dropdLink38: !this.state.dropdLink38 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="light">Dropdown</Button>
                                    <DropdownToggle caret color="light" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown direction="left" isOpen={this.state.dropdLink39} toggle={() => this.setState({ dropdLink39: !this.state.dropdLink39 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="dark">Dropdown</Button>
                                    <DropdownToggle caret color="dark" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Single Button Dropdowns Rounded</h4>
                                <UncontrolledDropdown >
                                    <DropdownToggle color='primary' caret className="float-left mr-5 mb-3 btn-rounded">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='secondary' caret className="float-left mr-5 mb-3 btn-rounded">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='success' caret className="float-left mr-5 mb-3 btn-rounded">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='danger' caret className="float-left mr-5 mb-3 btn-rounded">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='light' caret className="float-left mr-5 mb-3 btn-rounded">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='dark' caret className="float-left mr-5 mb-3 btn-rounded">
                                        Dropdown {' '}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Split Button Dropdowns rounded</h4>
                                <ButtonDropdown isOpen={this.state.dropdLink40} toggle={() => this.setState({ dropdLink40: !this.state.dropdLink40 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="primary" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="primary" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink41} toggle={() => this.setState({ dropdLink41: !this.state.dropdLink41 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="secondary" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="secondary" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink42} toggle={() => this.setState({ dropdLink42: !this.state.dropdLink42 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="success" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="success" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink43} toggle={() => this.setState({ dropdLink43: !this.state.dropdLink43 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="danger" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="danger" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink44} toggle={() => this.setState({ dropdLink44: !this.state.dropdLink44 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="warning" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="warning" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink45} toggle={() => this.setState({ dropdLink45: !this.state.dropdLink45 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="info" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="info" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink46} toggle={() => this.setState({ dropdLink46: !this.state.dropdLink46 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="light" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="light" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <ButtonDropdown isOpen={this.state.dropdLink47} toggle={() => this.setState({ dropdLink47: !this.state.dropdLink47 })} className="mb-3 mr-2 btn-split">
                                    <Button id="caret" color="dark" className="btn-flat">Dropdown</Button>
                                    <DropdownToggle caret color="dark" className="btn-flat" />
                                    <DropdownMenu>
                                        <DropdownItem>Dropdown</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Separated link</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Dropdown));