import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LaddaButton, {
    EXPAND_LEFT,
    EXPAND_RIGHT,
    EXPAND_UP,
    EXPAND_DOWN,
    SLIDE_LEFT,
    SLIDE_RIGHT,
    SLIDE_UP,
    SLIDE_DOWN,
    ZOOM_IN,
    ZOOM_OUT
} from 'react-ladda';

class LaddaButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expLeft: false,
            expRight: false,
            expUp: false,
            expDown: false,
            expSlideLeft: false,
            expSlideRight: false,
            expSlideUp: false,
            expSlideDown: false,
            expZoomIn: false,
            expZoomOut: false,
        };
    }

    toggle(name) {
        this.setState({
            [name]: !this.state[name],
            progress: 0.5,
        })
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {

        return (
            <React.Fragment>

                <Row>
                    <Col lg="6" className="stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Left Expanded Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expLeft}
                                    onClick={() => this.toggle('expLeft')}
                                    data-style={EXPAND_LEFT}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Right Expanded Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expRight}
                                    onClick={() => this.toggle('expRight')}
                                    data-style={EXPAND_RIGHT}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Top Expanded Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expUp}
                                    onClick={() => this.toggle('expUp')}
                                    data-style={EXPAND_UP}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Bottom Expanded Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expDown}
                                    onClick={() => this.toggle('expDown')}
                                    data-style={EXPAND_DOWN}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Zoom In Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expZoomIn}
                                    onClick={() => this.toggle('expZoomIn')}
                                    data-style={ZOOM_IN}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Zoom Out Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expZoomOut}
                                    onClick={() => this.toggle('expZoomOut')}
                                    data-style={ZOOM_OUT}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Slide Left Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expSlideLeft}
                                    onClick={() => this.toggle('expSlideLeft')}
                                    data-style={SLIDE_LEFT}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Slide Right Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expSlideRight}
                                    onClick={() => this.toggle('expSlideRight')}
                                    data-style={SLIDE_RIGHT}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Slide Up Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expSlideUp}
                                    onClick={() => this.toggle('expSlideUp')}
                                    data-style={SLIDE_UP}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Slide Down Button</h4>
                                <p>Click the following button to check the Demo. Follwoing is the Ladda Button Example</p>
                                <LaddaButton
                                    loading={this.state.expSlideDown}
                                    onClick={() => this.toggle('expSlideDown')}
                                    data-style={SLIDE_DOWN}
                                    className="btn btn-primary"
                                >
                                    Submit
                                </LaddaButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(LaddaButtons));