import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import LightData from './LightData';

const LoadingContainer = (props) => (
    <div>Loading Data...</div>
)

class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {}
        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    onMarkerClick(props, marker, e) {
        alert('You clicked in this marker');
    }

    render() {

        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Google Map</h4>
                                <div id="googlemap_container" style={{ position: "relative" }}>
                                    <Map google={this.props.google}
                                        style={{ width: '100%', height: '100%' }}
                                        zoom={14}>

                                        <Marker
                                            title={'The marker`s title will appear as a tooltip.'}
                                            name={'SOMA'}
                                            position={{ lat: 40.796384, lng: -74.093467 }} />
                                        <Marker name={'London Bridge'} />
                                        <InfoWindow>
                                            <div>
                                                <h1>{this.state.selectedPlace.name}</h1>
                                            </div>
                                        </InfoWindow>
                                    </Map>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" className="mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Google Map Light</h4>
                                <div id="googlemap_container" style={{ position: "relative" }}>
                                    <Map google={this.props.google} zoom={14} styles={LightData.Data} style={{ width: '100%', height: '100%' }}>
                                        <Marker onClick={this.onMarkerClick} />
                                        <InfoWindow>
                                            <div>
                                                <h1>{this.state.selectedPlace.name}</h1>
                                            </div>
                                        </InfoWindow>
                                    </Map>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

// export default withRouter(connect(null, { activateAuthLayout })(GoogleMap));
export default connect(null, { activateAuthLayout })(GoogleApiWrapper({
    apiKey: "AIzaSyB4JwWo5VPt9WyNp3Ne2uc2FMGEePHpqJ8",
    LoadingContainer: LoadingContainer,
    v: "3"
})(GoogleMap));