import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';

const DefaultEvents = [
    {
        title: 'All Day Event',
        start: new Date().setDate(new Date().getDate() + 1),
    },
    {
        id: 999,
        title: 'Repeating Event',
        start: new Date().setDate(new Date().getDate() - 5),
        allDay: false,
        className: 'bg-secondary'
    },
    {
        id: 999,
        title: 'Meeting',
        start: new Date().setDate(new Date().getDate() - 3),
        allDay: false,
        className: 'bg-primary'
    },
    {
        id: 999,
        title: 'Meeting',
        start: new Date().setDate(new Date().getDate() + 4),
        allDay: false,
        className: 'bg-warning'
    },
    {
        title: 'Meeting',
        start: new Date().setDate(new Date().getDate() + 4),
        allDay: false,
        className: 'bg-info'
    },
    {
        title: 'Meeting',
        start: new Date().setDate(new Date().getDate() + 3),
        allDay: false,
        className: 'bg-danger'
    },
];

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            calendarWeekends: true,
            calendarEvents: DefaultEvents,
        }
        this.calendarComponentRef = React.createRef();
        this.eventClick = this.eventClick.bind(this);
    }
    componentDidMount() {
        this.props.activateAuthLayout();
    }

    eventClick = (arg) => {
        var title = prompt('Event Title:');
        this.setState({ selectedDay: arg });
        if (title == null) { }
        else {
            var newEvent = {};
            newEvent = {
                id: this.state.calendarEvents.length + 1,
                title: title,
                start: this.state.selectedDay ? this.state.selectedDay.date : new Date(),
                className: 'bg-primary'
            };
            this.setState({
                calendarEvents: this.state.calendarEvents.concat(newEvent),
                selectedDay: null
            });

        }

    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <div className="full_calendar">
                                    <FullCalendar ref={this.calendarComponentRef} plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                                        handleWindowResize={true}
                                        themeSystem="bootstrap"
                                        dateClick={this.eventClick}
                                        editable={true}
                                        droppable={true}
                                        selectable={true}
                                        events={this.state.calendarEvents}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        )
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Calendar));